























































import { CustomerClient, CustomerGetResponse, PersonClient } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import Vue, { PropType } from 'vue'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'EditDialogCustomer',

  props: {
    showDialog: Boolean,
    customer: Object as PropType<CustomerGetResponse>
  },

  data: () => ({
    errorMessage: '',
    validation: new Validation(),
    toEditCustomer: {
      id: 0,
      name: '',
      kvId: 0
    },
    listOfKvNames: Array<Record<string, unknown>>(),
    customerClient: new CustomerClient(),
    personClient: new PersonClient()
  }),

  watch: {
    showDialog: {
      async handler (newVal) {
        if (newVal === false) {
          return
        }

        await this.loadKvs()

        this.toEditCustomer = {
          id: this.customer.id,
          name: this.customer.name,
          kvId: this.customer.kvId
        }
      },
      immediate: true
    },
    toEditCustomer: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    async loadKvs () {
      const personNamesResponse = await this.personClient.person_GetAllNames()
      if (personNamesResponse.errorMessage === '') {
        const names = personNamesResponse.names
        this.listOfKvNames = names.map(person => { return { value: person.id, text: person.name } })
      } else {
        this.errorMessage = i18n.t('editCustomerUnavailablePersonListNotAccessible') as string
      }
    },

    async editCustomer () {
      if (!this.checkForm()) {
        return
      }
      const customerResponse = await this.customerClient.customer_Edit({
        id: this.toEditCustomer.id,
        name: this.toEditCustomer.name.trim(),
        kvId: this.toEditCustomer.kvId
      })
      this.errorMessage = customerResponse.errorMessage
      if (customerResponse.errorMessage) {
        return
      }
      this.$emit('success')
    },

    checkForm: function () {
      return (this.validation.notempty(this.toEditCustomer.name) === true &&
        this.validation.notNumberNull(this.toEditCustomer.kvId) === true)
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.editCustomer()
      if (this.errorMessage === '' && this.checkForm()) {
        this.close()
      }
    }
  }
})
