


















































import { Validation } from '@/methods/Validation'
import Vue from 'vue'
import { AuthClient } from '@/api/clients'

export default Vue.extend({
  name: 'ResetPassword',

  props: {
    showDialog: Boolean
  },

  data: () => ({
    errorMessage: '',
    validation: new Validation(),
    mail: '',
    authClient: new AuthClient(),
    saving: false
  }),

  methods: {
    async resetPassword (email: string) {
      const personResponse = await this.authClient.auth_ResetPassword(email)
      this.errorMessage = personResponse.errorMessage
      if (this.errorMessage === '') {
        this.$emit('success')
      }
    },

    checkForm: function () {
      return (this.validation.notempty(this.mail) === true)
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      this.saving = true
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      if (!this.checkForm()) {
        this.saving = false
        return
      }
      await this.resetPassword(this.mail)
      if (this.errorMessage === '') {
        this.close()
      }
      this.saving = false
    }
  }
})
