




































































































































































import Vue from 'vue'
import {
  ProjectClient,
  CustomerClient,
  PersonClient,
  ProjectGetResponse,
  FrontendAction
} from '@/api/clients'
import EditDialogProject from '@/component/EditDialogProject.vue'
import i18n from '@/i18n'
import ListDialogMissingProjectFeedback from '@/component/ListDialogMissingProjectFeedback.vue'
import { isActionAvailable } from '@/api/availableActions'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ListProject',

  components: {
    IconWithTooltip,
    ListDialogMissingProjectFeedback,
    EditDialogProject
  },

  data: () => ({
    dialogEdit: false,
    projectCreatedDialog: false,
    dialogMissingProjectFeedback: false,
    errorMessage: '',
    showSuccessMessage: false,
    toEditProject: {} as ProjectGetResponse,

    headers: [
      { value: 'name', text: i18n.t('name') as string, sortable: true },
      { value: 'plName', text: i18n.t('projectLead') as string },
      { value: 'customerName', text: i18n.t('customer') as string },
      { value: 'startDate', text: i18n.t('startDate') as string },
      { value: 'endDate', text: i18n.t('endDate') as string },
      { value: 'action', text: i18n.t('editing') as string, align: 'center', sortable: false }
    ],

    search: '',
    canEditProjectAndAssignments: false,
    projects: Array<ProjectGetResponse>(),
    customers: Array<Record<string, unknown>>(),
    listOfPl: Array<Record<string, unknown>>(),
    projectsLoading: true,
    frontendActions: FrontendAction,
    personClient: new PersonClient(),
    customerClient: new CustomerClient(),
    projectClient: new ProjectClient(),
    createdProjectName: '',
    createdPlName: '',
    createdCustomerName: '',
    assignmentCreatedDialog: false,
    createdAssignmentPerson: '',
    createdAssignmentProject: '',
    createdAssignmentRole: ''
  }),

  watch: {
    async projectCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ListProject' })
      }
    },

    async assignmentCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ListProject' })
      }
    }
  },

  methods: {
    isActionAvailable,

    async loadProjects () {
      this.projectsLoading = true

      const personNameResponse = await this.personClient.person_GetAllNames()
      const customerResponse = await this.customerClient.customer_GetCustomerList()
      const projectResponse = isActionAvailable(this.frontendActions.ViewProjectsAndAssignments) ? await this.projectClient.project_GetAll() : await this.projectClient.project_GetProjectsForPl()

      if (personNameResponse.errorMessage !== '') {
        this.errorMessage = personNameResponse.errorMessage
      } else if (projectResponse.errorMessage !== '') {
        this.errorMessage = projectResponse.errorMessage
      } else if (customerResponse.errorMessage !== '') {
        const names = personNameResponse.names
        this.listOfPl = names.map(person => { return { value: person.id, text: person.name } })
        this.projects = projectResponse.projects
        this.canEditProjectAndAssignments = false
      } else {
        const names = personNameResponse.names
        this.listOfPl = names.map(person => { return { value: person.id, text: person.name } })
        this.customers = customerResponse.customers.map(c => {
          return { text: c.name, value: c.id }
        })
        this.projects = projectResponse.projects
        this.canEditProjectAndAssignments = isActionAvailable(this.frontendActions.EditProjectsAndAssignments)
      }

      this.projectsLoading = false
    },

    resetAllNotifications: function () {
      this.showSuccessMessage = false
      this.projectCreatedDialog = false
      this.errorMessage = ''
    },

    async validationDeleteProject (toDeleteProject: ProjectGetResponse) {
      if (toDeleteProject.isEditable) {
        this.resetAllNotifications()
        if (confirm(this.$i18n.t('listProjectPage.deleteMessage', { name: toDeleteProject.name }) as string)) {
          await this.deleteProject(toDeleteProject)
          await this.loadProjects()
        }
      }
    },

    async deleteProject (toDeleteProject: ProjectGetResponse) {
      const projectResponse = await this.projectClient.project_Delete(toDeleteProject.id, toDeleteProject.customerId)
      this.errorMessage = projectResponse.errorMessage
    },

    openEditProject: function (editedProject: ProjectGetResponse) {
      if (editedProject.isEditable) {
        this.resetAllNotifications()
        this.dialogEdit = true
        this.toEditProject = editedProject
      }
    },

    async closeEditProject () {
      this.dialogEdit = false
      await this.loadProjects()
    },

    editProjectSuccessful: function () {
      this.showSuccessMessage = true
    },

    async openDetailPage (item: ProjectGetResponse) {
      if (!this.dialogEdit) {
        await this.$router.push({ name: 'ViewProjectDetails', params: { id: String(item.id) } })
      }
    },

    openMissingProjectFeedbackDialog: function () {
      this.resetAllNotifications()
      this.dialogMissingProjectFeedback = true
    },

    closeMissingProjectFeedbackDialog () {
      this.dialogMissingProjectFeedback = false
    }
  },

  async beforeMount () {
    await this.loadProjects()

    this.createdProjectName = this.$route.params.createdProjectName
    this.createdPlName = this.$route.params.createdPlName
    this.createdCustomerName = this.$route.params.createdCustomerName
    if (!(this.createdProjectName === undefined && this.createdPlName === undefined && this.createdCustomerName === undefined)) {
      this.projectCreatedDialog = true
    }

    this.createdAssignmentPerson = this.$route.params.createdAssignmentPerson
    this.createdAssignmentProject = this.$route.params.createdAssignmentProject
    this.createdAssignmentRole = this.$route.params.createdAssignmentRole
    if (!(this.createdAssignmentPerson === undefined && this.createdAssignmentProject === undefined && this.createdAssignmentRole === undefined)) {
      this.assignmentCreatedDialog = true
    }
  }
})

