

























































import Vue, { PropType } from 'vue'
import { SelfFeedbackClient } from '@/api/clients'
import i18n from '@/i18n'
import { DisplayPerson } from '@/api/frontendOnlyTypes'

export default Vue.extend({
  name: 'ListDialogMissingSelfFeedback',

  props: {
    showDialog: Boolean,
    persons: Array as PropType<DisplayPerson[]>
  },

  watch: {
    showDialog: {
      handler: function (newVal) {
        if (newVal === false) {
          return
        }

        this.errorMessage = ''
        this.numberNotified = 0
        this.notifySuccess = false
      },
      immediate: true
    }
  },

  data: () => ({
    errorMessage: '',
    headers: [
      { value: 'persNo', text: i18n.t('personalNo') as string, sortable: true },
      { value: 'firstName', text: i18n.t('firstName') as string },
      { value: 'lastName', text: i18n.t('lastName') as string },
      { value: 'pvName', text: i18n.t('hrManager') as string }
    ],
    numberNotified: 0,
    notifySuccess: false,
    sendingMails: false,
    selfFeedbackClient: new SelfFeedbackClient()
  }),

  methods: {
    async sendSelfFeedbackReminder () {
      this.sendingMails = true

      const selfFeedbackResponse = await this.selfFeedbackClient.selfFeedback_NotifyMissingSelfFeedback()
      this.errorMessage = selfFeedbackResponse.errorMessage
      this.notifySuccess = selfFeedbackResponse.errorMessage !== ''
      this.numberNotified = selfFeedbackResponse.personIds.length

      this.sendingMails = false
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.sendSelfFeedbackReminder()
      if (this.errorMessage === '') {
        this.close()
      }
    }
  }
})
