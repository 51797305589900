import i18n from '@/i18n'
import moment from 'moment'

export class Validation {
  /**
   * Validation for personnel number. Has to be 1 to 10 digits.
   * @param persNo The personnel number to check.
   * @returns An error message, if errors are found. True, if no errors were found.
   */
  persNo (persNo: string): true|string {
    const reNumber = /^([0-9]){1,10}$/

    if (persNo == null || persNo === '') {
      return i18n.t('error.fieldRequired') as string
    } else if (!reNumber.test(persNo)) {
      return i18n.t('error.validPersonalNoRequired') as string
    }

    return true
  }

  /**
   * Validation for String. Has to contain something other than whitespace.
   * @param input: The input to check.
   * @returns An error message, if errors are found. True, if no errors were found.
   */
  notempty (input: string): true|string {
    if (input == null || input.trim() === '') {
      return i18n.t('error.fieldRequired') as string
    }

    return true
  }

  /**
   * Validation for Number. Has to be different than 0.
   * @param input: The input to check.
   * @returns An error message, if errors are found. True, if no errors were found.
   */
  notNumberNull (input: number): true|string {
    if (input == null || input === 0) {
      return i18n.t('error.fieldRequired') as string
    }

    return true
  }

  /**
   * Validation for Start- and Enddate of the Project or Assignment. Startdate has to be before Enddate.
   * @param startDate: The StartDate to check.
   * @param endDate: The EndDate to check.
   * @returns True if start date is before end date. Otherwise false.
   */
  validDates (startDate: string, endDate: string): boolean {
    const testStartDate = new Date(startDate)
    const testEndDate = new Date(endDate)
    return testStartDate < testEndDate
  }

  /**
   * Validation for Start- and Enddate of the Project or Assignment. Startdate has to be before Enddate.
   * @param startDate: The StartDate to check.
   * @param endDate: The EndDate to check.
   * @returns True if start date is before end date. Otherwise corresponding error message.
   */
  validDatesWithErrorMessage (startDate: string, endDate: string): boolean|string {
    if (this.validDates(startDate, endDate)) {
      return true
    }
    return i18n.t('error.endAfterStartDate') as string
  }

  /**
   * checks if a date is in the correct (German) format.
   * @param date: the date to check
   * @returns True if date has the correct format and is a valid date. Otherwise an error message.
   */
  dateFormat (date: string): boolean|string {
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(date)) {
      return i18n.t('error.dateFieldWrongFormat') as string
    }
    const dateFormat = 'DD.MM.YYYY'
    if (!moment(date, dateFormat, true).isValid()) {
      return i18n.t('error.dateFieldWrongDate') as string
    }
    return true
  }

  /**
   * Validation for password strength. Also checks that password has only Ascii chars.
   * @param password The password to check.
   * @returns An error message, if errors are found. True, if no errors were found.
   */
  password (password: string): true|string {
    const hasLowerCase = '(?=(.*[a-z])+)'
    const hasUpperCase = '(?=(.*[A-Z])+)'
    const hasNumber = '(?=(.*[0-9])+)'
    const hasSpecialChars = '(?=(.*[?!#$%&=*()-+.:,;<>_])+)'
    const hasMin12Chars = '.{12,}'

    const regexPwStrength = new RegExp('^' + hasLowerCase + hasUpperCase + hasNumber + hasSpecialChars +
      hasMin12Chars + '$')
    const regexIs12AsciiChars = /^[ -~]{12,}$/

    if (!regexPwStrength.test(password)) {
      return i18n.t('error.passwordStrength') as string
    } else if (!regexIs12AsciiChars.test(password)) {
      return i18n.t('error.passwordHasNonAsciiChars') as string
    }
    return true
  }

  /**
   * Compares the two input passwords and checks if they are equal.
   * @param password The new password to compare with ...
   * @param repeatedPassword ... the repeated password.
   * @returns An error message, if the passwords are different. True, if no errors were found.
   */
  equalPassword (password: string, repeatedPassword: string): true|string {
    if (password !== repeatedPassword) {
      return i18n.t('error.passwordMismatched') as string
    }
    return true
  }

  /**
   * Compares the new and old passwords and checks if they are equal.
   * @param newPassword The new password to compare with ...
   * @param oldPassword ... the old password.
   * @returns An error message, if the passwords match. True, if no errors were found.
   */
  differentPassword (newPassword: string, oldPassword: string): true|string {
    if (newPassword === oldPassword) {
      return i18n.t('error.passwordOldEqualsNew') as string
    }
    return true
  }

  /**
   * Validation for email format.
   * @param eMail The email to check.
   * @returns An error message, if errors are found. True, if no errors were found.
   */
  eMail (eMail: string): true|string {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/
    if (!regex.test(eMail)) {
      return i18n.t('error.eMailForm') as string
    }
    return true
  }
}
