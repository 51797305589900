
























































































































import Vue from 'vue'
import { DepartmentClient, FrontendAction, JobLevel, PersonClient } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import { EnumConverter } from '@/methods/EnumConverter'
import i18n from '@/i18n'
import { isActionAvailable } from '@/api/availableActions'

export default Vue.extend({
  name: 'AddPerson',

  data: () => ({
    firstName: '',
    lastName: '',
    persNo: '',
    pvId: 0,
    mail: '',
    departmentId: 0,
    jobLevel: '',
    errorMessage: '',
    validation: new Validation(),
    enumConverter: new EnumConverter(),
    listOfPv: Array<Record<string, unknown>>(),
    pvLoading: true,
    listOfDepartmentLocation: Array<Record<string, unknown>>(),
    listOfDepartmentLoading: true,
    listOfJobLevel: Array<string>(),
    isAdmin: false,
    isPv: false,
    personClient: new PersonClient(),
    departmentClient: new DepartmentClient(),
    frontendActions: FrontendAction,
    creatingPerson: false
  }),

  computed: {
    formData () {
      return [this.persNo, this.firstName, this.lastName, this.mail, this.departmentId, this.jobLevel, this.pvId, this.isAdmin, this.isPv]
    }
  },

  watch: {
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    isActionAvailable,

    /**
     * Performs plausibility check and adds employee to database, if passed.
     */
    async createPerson () {
      this.creatingPerson = true
      this.errorMessage = ''

      const refForm = this.$refs.personForm as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        this.creatingPerson = false
        return
      }
      const personResponse = await this.personClient.person_Post({
        mail: this.mail.trim(),
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        persNo: this.persNo,
        departmentId: this.departmentId,
        jobLevel: this.enumConverter.toEnumValueForJobLevels(this.jobLevel),
        pvId: this.pvId,
        isAdmin: this.isAdmin,
        isPv: this.isPv
      })

      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = personResponse.errorMessage
      if (personResponse.errorMessage) {
        this.creatingPerson = false
        return
      }

      // go back to person list
      // params will be used for user feedback
      await this.$router.push({
        name: 'ListPersonWithParams',
        params: {
          createdPersonName: this.firstName + ' ' + this.lastName,
          createdPvName: this.listOfPv.find(pv => pv.value === this.pvId)!.text as string
        }
      })
    },

    async loadListOfPv () {
      this.pvLoading = true

      const response = await this.personClient.person_GetAllPvNames()
      if (response.errorMessage === '') {
        const names = response.names
        this.listOfPv = names.map(person => { return { value: person.id, text: person.name } })
      } else {
        const personLocalizedText = i18n.t('employee')
        const pvLocalizedText = i18n.t('hrManager')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: personLocalizedText, listOf: pvLocalizedText }) as string
      }

      this.pvLoading = false
    },

    async loadListOfDepartmentLocation () {
      this.listOfDepartmentLoading = true

      const response = await this.departmentClient.department_GetAll()
      if (response.errorMessage === '') {
        const departments = response.departments
        this.listOfDepartmentLocation = departments.map(department => { return { value: department.id, text: department.name } })
      } else {
        const personLocalizedText = i18n.t('employee')
        const pvLocalizedText = i18n.t('departmentLocation')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: personLocalizedText, listOf: pvLocalizedText }) as string
      }

      this.listOfDepartmentLoading = false
    },

    loadListOfJobLevel: function () {
      // Separate the integer and terms of levels, so that only the terms of levels are displayed in the dropdown
      for (const level in JobLevel) {
        const isNumber = isFinite(parseInt(level))
        if (!isNumber) {
          this.listOfJobLevel.push(this.enumConverter.toLocalizedTextForJobLevels(level))
        }
      }
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      return (this.validation.persNo(this.persNo) === true &&
        this.validation.eMail(this.mail) === true &&
        this.validation.notempty(this.firstName) === true &&
        this.validation.notempty(this.lastName) === true &&
        this.validation.notNumberNull(this.departmentId) === true &&
        this.validation.notempty(this.jobLevel) === true &&
        this.validation.notNumberNull(this.pvId) === true)
    }
  },

  async beforeMount () {
    await this.loadListOfPv()
    await this.loadListOfDepartmentLocation()
    this.loadListOfJobLevel()
  }
})

