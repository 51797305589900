


























































































import Vue from 'vue'
import { Validation } from '@/methods/Validation'
import { AuthClient, AuthResponse } from '@/api/clients'
import ResetPassword from '@/component/ResetPassword.vue'
import auth from '@/api/auth'

export default Vue.extend({
  name: 'Login',
  components: { ResetPassword },
  data: () => ({
    validation: new Validation(),
    mail: '',
    password: '',
    loggingIn: false,
    loggingInAD: false,
    showPw: false,
    loginError: false,
    dialogResetPassword: false,
    showSuccessMessage: false,
    errorMessage: '',
    authClient: new AuthClient()
  }),

  computed: {
    formData () {
      return [this.mail, this.password]
    }
  },

  watch: {
    formData: {
      handler: function () {
        this.errorMessage = ''
        this.loginError = false
      },
      deep: true
    }
  },

  methods: {

    async loginWithAD () {
      await auth.login()
      var user = auth.user()
      this.loginError = false
      this.loggingInAD = true
      let response: AuthResponse
      try {
        response = await this.authClient.auth_GetLocalUser({
          mail: user.username,
          password: ''
        })
      } catch {
        this.loginError = true
        this.loggingInAD = false
        return
      }
      this.loggingInAD = false

      // Login successful if there's a jwt token in the response
      if (!response.token) {
        this.loginError = true
        return
      }
      // Store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(response))
      await this.$router.push({ name: 'Home' })
    },

    async loginUser (e: Event) {
      e.preventDefault()
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      if (!this.checkForm()) {
        return
      }

      this.loginError = false
      this.loggingIn = true
      let response: AuthResponse
      try {
        response = await this.authClient.auth_Authenticate({
          mail: this.mail,
          password: this.password
        })
      } catch {
        this.loginError = true
        this.loggingIn = false
        return
      }
      this.loggingIn = false

      // Login successful if there's a jwt token in the response
      if (!response.token) {
        this.loginError = true
        return
      }
      // Store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(response))

      // Next page depends on whether it is the first login or not.
      if (response.autogeneratedPw) {
        await this.$router.push({ name: 'ChangeUserPw', query: { firstLogin: 'true' } })
      } else {
        await this.$router.push({ name: 'Home' })
      }
    },

    openResetPassword: function () {
      this.showSuccessMessage = false
      this.dialogResetPassword = true
      this.errorMessage = ''
    },

    closeResetPassword: function () {
      this.dialogResetPassword = false
    },

    resetPasswordSuccessful: function () {
      this.showSuccessMessage = true
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      return (this.validation.notempty(this.mail) === true &&
        this.validation.notempty(this.password) === true)
    }
  }
})
