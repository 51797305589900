













































































































































import Vue from 'vue'
import { DepartmentClient, FrontendAction, PersonClient, PersonGetResponse, SelfFeedbackClient } from '@/api/clients'
import { Dictionary } from 'vue-router/types/router'
import EditDialogPerson from '@/component/EditDialogPerson.vue'
import ListDialogMissingSelfFeedback from '@/component/ListDialogMissingSelfFeedback.vue'
import VerifyKeyCreateReportDialog from '@/component/VerifyKeyCreateReportDialog.vue'
import i18n from '@/i18n'
import { EnumConverter } from '@/methods/EnumConverter'
import { isActionAvailable } from '@/api/availableActions'
import { DisplayPerson } from '@/api/frontendOnlyTypes'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ListPerson',

  components: {
    IconWithTooltip,
    VerifyKeyCreateReportDialog,
    ListDialogMissingSelfFeedback,
    EditDialogPerson
  },

  data: () => ({
    dialogEdit: false,
    dialogMissingSelfFeedback: false,
    dialogVerifyKey: false,
    dialogEditKey: false,
    personCreatedDialog: false,
    errorMessage: '',
    showSuccessMessageEditing: false,

    headers: [
      { value: 'persNo', text: i18n.t('personalNo') as string, sortable: true },
      { value: 'firstName', text: i18n.t('firstName') as string },
      { value: 'lastName', text: i18n.t('lastName') as string },
      { value: 'departmentName', text: i18n.t('location') as string },
      { value: 'pvName', text: i18n.t('hrManager') as string },
      { value: 'selfFeedbackStatus', text: i18n.t('feedback.selfFeedbackStatus') as string },
      { value: 'action', text: i18n.t('editing') as string, align: 'center', sortable: false }
    ],

    toEditPerson: {},
    personsWithNoFeedback: Array<DisplayPerson>(),
    search: '',
    persons: Array<DisplayPerson>(),
    listPvs: Array<Record<string, unknown>>(),
    personsLoading: true,
    frontendActions: FrontendAction,
    enumConverter: new EnumConverter(),
    personClient: new PersonClient(),
    feedbackClient: new SelfFeedbackClient(),
    departmentClient: new DepartmentClient(),
    createdPersonName: '',
    createdPvName: '',
    listOfDepartmentLoading: true,
    listDepartments: Array<Record<string, unknown>>()
  }),

  watch: {
    async personCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ListPerson' })
      }
    }
  },

  methods: {
    isActionAvailable,

    async convertPersonDetails (personRetrieved: Array<PersonGetResponse>, selfFeedbacksRetrieved?: Dictionary<string>) {
      this.persons = Array<DisplayPerson>()
      this.listPvs = Array<Record<string, unknown>>()
      this.personsWithNoFeedback = Array<DisplayPerson>()

      for (const person of personRetrieved) {
        const processedPerson: DisplayPerson = {
          id: person.id,
          persNo: person.persNo,
          firstName: person.firstName,
          lastName: person.lastName,
          departmentId: person.departmentId,
          departmentName: person.departmentName,
          jobLevel: this.enumConverter.toLocalizedTextForJobLevels(person.jobLevel),
          pvName: person.pvName,
          pvId: person.pvId,
          hasNoPv: person.pvName === '',
          isAdmin: person.isAdmin,
          isPv: person.isPv,
          selfFeedbackStatus: i18n.t('feedback.isNotAvailable') as string,
          lastSelfFeedbackReminderDate: person.lastSelfFeedbackReminderDate
        }

        if (selfFeedbacksRetrieved === undefined) {
          processedPerson.selfFeedbackStatus = i18n.t('feedback.isUnknown') as string
        } else {
          if (person.id in selfFeedbacksRetrieved) {
            processedPerson.selfFeedbackStatus = i18n.t('feedback.isAvailable',
              { submitDate: i18n.d(new Date(selfFeedbacksRetrieved[person.id])) }) as string
          } else {
            this.personsWithNoFeedback.push(processedPerson)

            const lastSelfFeedbackReminderDate = new Date(person.lastSelfFeedbackReminderDate)
            if (lastSelfFeedbackReminderDate.getTime() > 0) {
              processedPerson.selfFeedbackStatus = i18n.t('feedback.isNotAvailableWithReminderDate',
                { reminderDate: i18n.d(lastSelfFeedbackReminderDate) }) as string
            }
          }
        }

        this.persons.push(processedPerson)
        if (person.isPv) {
          this.listPvs.push({
            text: person.firstName + ' ' + person.lastName,
            value: person.id
          })
        }
      }
    },

    async loadPersons () {
      this.personsLoading = true
      const personsResponse = await this.personClient.person_GetAll()
      const selfFeedbackResponse = await this.feedbackClient.selfFeedback_GetAll()

      if (personsResponse.errorMessage !== '') {
        this.errorMessage = personsResponse.errorMessage
      } else if (selfFeedbackResponse.errorMessage !== '') {
        await this.convertPersonDetails(personsResponse.persons)
      } else {
        await this.convertPersonDetails(personsResponse.persons, selfFeedbackResponse.selfFeedbacks)
      }

      this.personsLoading = false
    },

    async listOfDepartmentLocation () {
      this.listOfDepartmentLoading = true

      const response = await this.departmentClient.department_GetAll()
      if (response.errorMessage === '') {
        const departments = response.departments
        this.listDepartments = departments.map(department => { return { value: department.id, text: department.name } })
      } else {
        const personLocalizedText = i18n.t('employee')
        const pvLocalizedText = i18n.t('departmentLocation')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: personLocalizedText, listOf: pvLocalizedText }) as string
      }

      this.listOfDepartmentLoading = false
    },

    async validationDeletePerson (toDeletePerson: DisplayPerson) {
      this.resetAllNotifications()
      if (confirm(this.$i18n.t('listPersonPage.deleteMessage', { name: toDeletePerson.firstName + ' ' + toDeletePerson.lastName }) as string)) {
        await this.deletePerson(toDeletePerson)
        await this.loadPersons()
      }
    },

    async deletePerson (toDeletePerson: DisplayPerson) {
      const personResponse = await this.personClient.person_Delete(toDeletePerson.id)
      this.errorMessage = personResponse.errorMessage
    },

    resetAllNotifications: function () {
      this.showSuccessMessageEditing = false
      this.errorMessage = ''
    },

    openEditPerson: function (editedPerson: DisplayPerson) {
      this.resetAllNotifications()
      this.dialogEdit = true
      this.toEditPerson = editedPerson
    },

    async closeEditPerson () {
      this.dialogEdit = false
      await this.loadPersons()
    },

    editPersonSuccessful: function () {
      this.showSuccessMessageEditing = true
    },

    openVerifyKeyDialog: function () {
      this.dialogVerifyKey = true
      this.resetAllNotifications()
    },

    async closeVerifyKey () {
      this.dialogVerifyKey = false
      await this.loadPersons()
    },

    async openDetailPage (item: DisplayPerson) {
      if (!this.dialogEdit) {
        await this.$router.push({ name: 'ViewPersonDetails', params: { id: String(item.id) } })
      }
    },

    openMissingSelfFeedbackDialog: function () {
      this.resetAllNotifications()
      this.dialogMissingSelfFeedback = true
    },

    closeMissingSelfFeedbackDialog () {
      this.dialogMissingSelfFeedback = false
    }
  },

  async beforeMount () {
    await this.loadPersons()
    await this.listOfDepartmentLocation()

    this.createdPersonName = this.$route.params.createdPersonName
    this.createdPvName = this.$route.params.createdPvName
    if (!(this.createdPersonName === undefined && this.createdPvName === undefined)) {
      this.personCreatedDialog = true
    }
  }
})

