



























































































































































import Vue from 'vue'
import {
  AssignmentClient,
  ProjectClient,
  CustomerClient,
  PersonClient,
  ProjectGetResponse,
  AssignmentRoleClient
} from '@/api/clients'
import { Validation } from '@/methods/Validation'
import { EnumConverter } from '@/methods/EnumConverter'
import { DateConverter } from '@/methods/DateConverter'
import i18n from '@/i18n'
import moment from 'moment'

const previousPageListProject = 'ListProject'
const previousPageViewProjectDetails = 'ViewProjectDetails'
const pathListProject = 'ListProjectWithParamsCreatedAssignment'
const pathViewProjectDetails = 'ViewProjectDetailsWithParamsCreatedAssigment'

export default Vue.extend({
  name: 'AddAssignment',
  data: () => ({
    id: 0,
    personId: 0,
    projectId: 0,
    roleId: 0,
    role: '',
    lastPersonName: '',
    lastRole: '',
    lastProjectName: '',
    errorMessage: '',
    listOfPerson: Array<Record<string, unknown>>(),
    listOfAssignmentRoles: Array<Record<string, unknown>>(),
    personLoading: true,
    assignmentRolesLoading: true,
    listOfCustomerAndProject: Array<Record<string, unknown>>(),
    mapOfCustomerAndProjectById: new Map(),
    customerAndProjectLoading: true,
    assignmentCreated: false,
    validation: new Validation(),
    dateConverter: new DateConverter(),
    startDatePerson: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    startDateFormatted: '',
    endDatePerson: '',
    endDateFormatted: '',
    startDateProject: '',
    endDateProject: '',
    listOfRole: Array<string>(),
    filledParams: false,
    enumConverter: new EnumConverter(),
    personClient: new PersonClient(),
    customerClient: new CustomerClient(),
    projectClient: new ProjectClient(),
    assignmentClient: new AssignmentClient(),
    assignmentRoleClient: new AssignmentRoleClient(),
    startDateMenu: false,
    endDateMenu: false,
    previousPage: ''
  }),

  watch: {
    startDatePerson () {
      this.startDateFormatted = this.dateConverter.formatDate(this.startDatePerson)
    },
    endDatePerson () {
      this.endDateFormatted = this.dateConverter.formatDate(this.endDatePerson)
    },
    projectId () {
      if (this.projectId !== 0) {
        this.startDateProject = (new Date((new Date(this.mapOfCustomerAndProjectById.get(this.projectId).startDate)).getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.endDateProject = (new Date((new Date(this.mapOfCustomerAndProjectById.get(this.projectId).endDate)).getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.startDatePerson = this.startDateProject
        this.endDatePerson = this.endDateProject
      }
    },
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  computed: {
    formData () {
      return [this.personId, this.role, this.startDateProject, this.endDateProject]
    }
  },

  methods: {
    async loadListOfPerson () {
      this.personLoading = true

      const personNamesResponse = await this.personClient.person_GetAllNames()
      if (personNamesResponse.errorMessage === '') {
        const names = personNamesResponse.names
        this.listOfPerson = names.map(person => { return { value: person.id, text: person.name } })
      } else {
        const assignmentLocalizedText = i18n.t('assignment')
        const personLocalizedText = i18n.t('employee')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: assignmentLocalizedText, listOf: personLocalizedText }) as string
      }

      this.personLoading = false
    },

    listCustomerAndProject: function (projectRetrieved: Array<ProjectGetResponse>) {
      projectRetrieved.forEach((project: ProjectGetResponse) => {
        this.listOfCustomerAndProject.push({
          text: project.customerName + ' - ' + project.name,
          value: project.id
        })
        this.mapOfCustomerAndProjectById.set(
          project.id, {
            customer: project.customerName,
            customerId: project.customerId,
            project: project.name,
            startDate: project.startDate,
            endDate: project.endDate
          }

        )
        this.listOfCustomerAndProject.sort()
      })
    },

    async loadListOfCustomerAndProject () {
      this.customerAndProjectLoading = true
      const projectResponse = await this.projectClient.project_GetProjectsForKv()

      if (projectResponse.errorMessage !== '') {
        const assignmentLocalizedText = i18n.t('assignment')
        const customerLocalizedText = i18n.t('customersAndProjects')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: assignmentLocalizedText, listOf: customerLocalizedText }) as string
      } else {
        this.listCustomerAndProject(projectResponse.projects)
      }

      this.customerAndProjectLoading = false
    },

    async loadListOfAssignmentRole () {
      this.assignmentRolesLoading = true

      const assignmentRolesResponse = await this.assignmentRoleClient.assignmentRole_GetAll()
      if (assignmentRolesResponse.errorMessage === '') {
        const assignmentRoles = assignmentRolesResponse.assignmentRoles
        this.listOfAssignmentRoles = assignmentRoles.map(assignmentRole => { return { value: assignmentRole.id, text: assignmentRole.name } })
      } else {
        const assignmentLocalizedText = i18n.t('assignment')
        const AssignmentRolesLocalizedText = i18n.t('assignmentRole')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: assignmentLocalizedText, listOf: AssignmentRolesLocalizedText }) as string
      }

      this.assignmentRolesLoading = false
    },

    async createAssignment (goBack = false) {
      const refForm = this.$refs.assignmentForm as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        this.assignmentCreated = false
        return
      }

      const customerId = this.mapOfCustomerAndProjectById.get(this.projectId).customerId
      const assignmentResponse = await this.assignmentClient.assignment_Post({
        id: this.id,
        personId: this.personId,
        roleId: this.roleId,
        startDate: this.startDatePerson,
        endDate: this.endDatePerson,
        projectId: this.projectId
      }, customerId)
      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = assignmentResponse.errorMessage
      if (assignmentResponse.errorMessage) {
        this.assignmentCreated = false
        return
      }

      const customerAndProjectRecord = this.listOfCustomerAndProject.find(c => c.value === this.projectId)
      let customerAndProject = ''
      if (customerAndProjectRecord !== undefined) {
        customerAndProject = customerAndProjectRecord.text as string
      }

      const personRecord = this.listOfPerson.find(p => p.value === this.personId)
      let personName = ''
      if (personRecord !== undefined) {
        personName = personRecord.text as string
      }

      const assignmentRoleRecord = this.listOfAssignmentRoles.find(a => a.value === this.roleId)
      let assignmentRoleName = ''
      if (assignmentRoleRecord !== undefined) {
        assignmentRoleName = assignmentRoleRecord.text as string
      }

      if (goBack && this.previousPage !== '') {
        const parameters = {
          createdAssignmentPerson: personName,
          createdAssignmentRole: assignmentRoleName,
          createdAssignmentProject: customerAndProject,
          id: ''
        }
        // go back to previous page
        // params will be used for user feedback
        if (this.previousPage === previousPageListProject) {
          await this.$router.push({
            name: pathListProject,
            params: parameters
          })
        } else if (this.previousPage === previousPageViewProjectDetails) {
          parameters.id = String(this.projectId)
          await this.$router.push({
            name: pathViewProjectDetails,
            params: parameters
          })
        }
      } else {
        // Before resetting the form the entered data is saved for user feedback.
        this.lastProjectName = customerAndProject
        this.lastPersonName = personName
        this.lastRole = assignmentRoleName

        this.assignmentCreated = true
        this.personId = 0
        this.roleId = 0
      }
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      if (!this.validation.validDates(this.startDatePerson, this.endDatePerson)) {
        this.errorMessage = this.$i18n.t('error.startBeforeEndDate') as string
        return false
      }
      this.errorMessage = ''
      return (this.validation.notNumberNull(this.personId) === true &&
        this.validation.notNumberNull(this.projectId) === true &&
        this.validation.notNumberNull(this.roleId) === true
      )
    }
  },

  async beforeMount () {
    moment.locale('de-de')
    await this.loadListOfPerson()
    await this.loadListOfCustomerAndProject()
    await this.loadListOfAssignmentRole()

    const projectId = this.$route.query.projectId
    if (projectId !== undefined && typeof parseInt(projectId as string) === 'number') {
      this.projectId = parseInt(projectId as string)
      this.filledParams = true
    }
    this.startDateFormatted = this.dateConverter.formatDate(this.startDatePerson)
    this.endDateFormatted = this.dateConverter.formatDate(this.endDatePerson)

    this.previousPage = this.$route.params.previousPage
    if (this.previousPage !== previousPageListProject && this.previousPage !== previousPageViewProjectDetails) {
      this.previousPage = ''
    }
  }
})
