


















import Vue from 'vue'

export default Vue.extend({
  name: 'IconWithTooltip',

  props: {
    disabled: Boolean,
    tooltipText: String,
    icon: String,
    classesIcon: String,
    small: Boolean
  },

  methods: {
    callParentMethod: function () {
      this.$emit('iconWithTooltipMethodTrigger')
    }
  }
})

