





























































































































import Vue, { PropType } from 'vue'
import { FrontendAction, JobLevel, PersonClient } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import { EnumConverter } from '@/methods/EnumConverter'
import { isActionAvailable } from '@/api/availableActions'

export default Vue.extend({
  name: 'EditDialogPerson',

  props: {
    showDialog: Boolean,
    id: Number,
    persNo: String,
    firstName: String,
    lastName: String,
    departmentId: Number,
    jobLevel: String,
    pvId: Number,
    isAdmin: Boolean,
    isPv: Boolean,
    listPvs: Array as PropType<Record<string, unknown>[]>,
    listDepartments: Array as PropType<Record<string, unknown>[]>
  },

  data: () => ({
    errorMessage: '',

    toEditPerson: {
      id: 0,
      persNo: '',
      firstName: '',
      lastName: '',
      departmentId: 0,
      jobLevel: '',
      pvId: 0,
      isAdmin: false,
      isPv: false
    },

    validation: new Validation(),
    enumConverter: new EnumConverter(),
    personClient: new PersonClient(),
    frontendActions: FrontendAction
  }),

  computed: {
    listOfJobLevel: function () {
      // Separate the integer and terms of levels, so that only the terms of levels are displayed in the dropdown
      const listOfJobLevel = [] as string[]
      for (const level in JobLevel) {
        const isNumber = isFinite(parseInt(level))
        if (!isNumber) {
          listOfJobLevel.push(this.enumConverter.toLocalizedTextForJobLevels(level))
        }
      }
      return listOfJobLevel
    },

    formData () {
      return [
        this.toEditPerson.persNo,
        this.toEditPerson.firstName,
        this.toEditPerson.lastName,
        this.toEditPerson.departmentId,
        this.toEditPerson.jobLevel,
        this.toEditPerson.pvId,
        this.toEditPerson.isPv
      ]
    }
  },

  watch: {
    showDialog: {
      handler: function (newVal) {
        if (newVal === false) {
          return
        }
        this.toEditPerson = {
          id: this.id,
          persNo: this.persNo,
          firstName: this.firstName,
          lastName: this.lastName,
          departmentId: this.departmentId,
          jobLevel: this.jobLevel,
          pvId: this.pvId,
          isAdmin: this.isAdmin,
          isPv: this.isPv
        }
      },
      immediate: true
    },
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    isActionAvailable,

    async editPerson () {
      if (!this.checkForm()) {
        return
      }
      const personResponse = await this.personClient.person_Edit({
        id: this.toEditPerson.id,
        firstName: this.toEditPerson.firstName.trim(),
        lastName: this.toEditPerson.lastName.trim(),
        persNo: this.toEditPerson.persNo,
        departmentId: this.toEditPerson.departmentId,
        jobLevel: this.enumConverter.toEnumValueForJobLevels(this.toEditPerson.jobLevel),
        pvId: this.toEditPerson.pvId,
        isAdmin: this.toEditPerson.isAdmin,
        isPv: this.toEditPerson.isPv
      })
      this.errorMessage = personResponse.errorMessage
      if (personResponse.errorMessage) {
        return
      }
      this.$emit('success')
    },

    checkForm: function () {
      return (this.validation.persNo(this.toEditPerson.persNo) === true &&
        this.validation.notempty(this.toEditPerson.firstName) === true &&
        this.validation.notempty(this.toEditPerson.lastName) === true &&
        this.validation.notNumberNull(this.toEditPerson.departmentId) === true &&
        this.validation.notempty(this.toEditPerson.jobLevel) === true &&
        this.validation.notNumberNull(this.toEditPerson.pvId) === true)
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.editPerson()
      if (this.errorMessage === '' && this.checkForm()) {
        this.close()
      }
    }
  }
})
