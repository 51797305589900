











export default {
  name: 'ErrorPage'
}
