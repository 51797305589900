













































































































































































import Vue from 'vue'
import {
  AssignmentClient,
  AssignmentGetResponse,
  ProjectFeedbackClient,
  ProjectFeedbackGetResponse
} from '@/api/clients'
import { Validation } from '@/methods/Validation'
import i18n from '@/i18n'
import { EnumConverter } from '@/methods/EnumConverter'

export default Vue.extend({
  name: 'AddProjectFeedback',

  data: () => ({
    projectId: 0,
    assignmentId: 0,
    content: '',
    errorMessage: '',
    editMessage: '',
    validation: new Validation(),
    feedbackLoading: false,
    startDate: '',
    endDate: '',
    assignmentPeriod: '',
    personName: '',
    projectName: '',
    customerName: '',
    role: '',
    enumConverter: new EnumConverter(),
    projectFeedbackClient: new ProjectFeedbackClient(),
    assignmentClient: new AssignmentClient(),
    feedbackOverwriteWarningShown: false,
    feedbackOverwriteWarningShownToSave: false,
    dialog: false,
    Geteilt: false,
    uploaded: false
  }),

  watch: {
    content: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    openConfirmDialog () {
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      if (this.checkForm()) {
        this.dialog = true
      } else {
        this.errorMessage = this.$i18n.t('addProjectFeedbackPage.checkFormErrorMessage') as string
      }
    },

    async confirmDialog () {
      await this.createProjectFeedback()
      this.dialog = false
    },

    async createProjectFeedback () {
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        this.errorMessage = this.$i18n.t('addProjectFeedbackPage.checkFormErrorMessage') as string
        return
      }

      const personResponse = await this.projectFeedbackClient.projectFeedback_Put(this.assignmentId, this.feedbackOverwriteWarningShownToSave, this.Geteilt, null)

      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = personResponse.errorMessage
      if (personResponse.errorMessage) {
        return
      }

      // go back to project details
      // params will be used for user feedback
      await this.$router.push({
        name: 'ViewProjectDetailsWithParamsFeedback',
        params: {
          id: String(this.projectId),
          feedbackCreated: String(true)
        }
      })
    },

    convertAssignment: function (assignmentRetrieved: AssignmentGetResponse) {
      this.role = assignmentRetrieved.roleName
      this.startDate = i18n.d(new Date(assignmentRetrieved.startDate), 'short')
      this.endDate = i18n.d(new Date(assignmentRetrieved.endDate), 'short')
      this.assignmentPeriod = this.startDate + ' - ' + this.endDate
      this.personName = assignmentRetrieved.personName
      this.projectName = assignmentRetrieved.projectName
      this.customerName = assignmentRetrieved.customerName
    },

    checkForm: function () {
      return (this.validation.notempty(this.content) === true)
    },

    decisionContentAddOrEdit (projectFeedbackRetrieved: ProjectFeedbackGetResponse) {
      if (projectFeedbackRetrieved.hasContent) {
        const submitDate = i18n.d(new Date(projectFeedbackRetrieved.submitDate), 'short')
        this.editMessage = this.$i18n.t('addProjectFeedbackPage.editMessage', { submitDate: submitDate }) as string
      } else {
        this.editMessage = ''
      }
    },

    async loadProjectFeedback () {
      this.feedbackLoading = true

      this.projectId = parseInt(this.$route.params.projectId)
      this.assignmentId = parseInt(this.$route.params.assignmentId)
      if (isNaN(this.projectId) || isNaN(this.assignmentId)) {
        await this.$router.push({ name: 'ErrorPage' })
        return
      }

      let assignmentResponse: AssignmentGetResponse
      let projectFeedbackResponse: ProjectFeedbackGetResponse

      try {
        assignmentResponse = await this.assignmentClient.assignment_Get(this.assignmentId)
        projectFeedbackResponse = await this.projectFeedbackClient.projectFeedback_Get(this.assignmentId)
      } catch {
        await this.$router.push({ name: 'ErrorPage' })
        return
      }

      if (assignmentResponse.errorMessage !== '') {
        this.errorMessage = assignmentResponse.errorMessage
      } else if (projectFeedbackResponse.errorMessage !== '') {
        this.errorMessage = projectFeedbackResponse.errorMessage
      } else {
        this.convertAssignment(await this.assignmentClient.assignment_Get(this.assignmentId))
        this.decisionContentAddOrEdit(projectFeedbackResponse)
        this.feedbackOverwriteWarningShown = projectFeedbackResponse.feedbackOverwriteWarningShown
      }

      this.feedbackLoading = false
    }
  },

  async beforeMount () {
    await this.loadProjectFeedback()
  }
})
