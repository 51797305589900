
















































































































import Vue from 'vue'
import {
  AssignmentClient,
  PersonClient,
  ProjectClient,
  ProjectFeedbackClient,
  ProjectGetResponse,
  AssignmentGetResponse,
  ProjectFeedbackGetResponse,
  ProjectFeedbackFile, FileResponse
} from '@/api/clients'
import i18n from '@/i18n'
import { DisplayAssignment } from '@/api/frontendOnlyTypes'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ListDownloadFiles',

  components: {
    IconWithTooltip
  },

  data: () => ({
    projectName: '',
    personName: '',
    projectId: 0,
    assignmentId: 0,
    personId: 0,
    numberOfFilesForPerson: 0,
    projectFeedbackStatus: '',
    errorMessage: '',
    showSuccessMessage: false,
    selectedFile: {} as File,
    selectedItem: {} as DisplayAssignment,
    selectedFileDialog: false,
    files: [] as ProjectFeedbackFile[],
    selectedDownloadFile: {} as ProjectFeedbackFile,

    headers: [
      { value: 'roleName', text: i18n.t('assignment') as string },
      { value: 'projectFeedbackStatus', text: i18n.t('feedback.projectFeedbackStatus') as string },
      { value: 'upload', text: i18n.t('feedback.projectFeedback') as string, align: 'center', sortable: false }
    ],

    assignment: {} as DisplayAssignment,
    assignments: Array<DisplayAssignment>(),
    employees: [] as DisplayAssignment[],
    siteLoading: true,
    downloadAllForPerson: false,
    assignmentDetailsLoading: true,
    assignmentClient: new AssignmentClient(),
    personClient: new PersonClient(),
    projectClient: new ProjectClient(),
    projectFeedbackClient: new ProjectFeedbackClient()
  }),

  methods: {

    async showFiles (item:DisplayAssignment) {
      this.selectedItem = item
      this.selectedDownloadFile = {} as ProjectFeedbackFile
      const projectFeedbackResponse = await this.projectFeedbackClient.projectFeedback_Get(item.assignmentId)
      this.files = projectFeedbackResponse.projectFeedbackFiles
    },

    download (projectFeedbacksResponse : FileResponse, fileName = '') {
      const url = window.URL.createObjectURL(projectFeedbacksResponse.data)
      const link = document.createElement('a')
      link.href = url
      if (fileName === '') {
        fileName = this.selectedDownloadFile.fileName + '.' + this.selectedDownloadFile.fileType
      }
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },

    async downloadFile () {
      if (this.selectedDownloadFile.id !== undefined) {
        const projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_DownloadFileForUser(this.selectedDownloadFile.id)
        this.download(projectFeedbacksResponse)
        this.selectedFileDialog = false
      }
    },

    async downloadAllFiles () {
      const projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_DownloadAllFilesForPerson(this.personId)
      this.download(projectFeedbacksResponse, 'Feedbacks_' + this.personName.replaceAll(' ', '_') + '.zip')
    },

    async getNumberOfFilesForPerson () {
      this.numberOfFilesForPerson = await this.projectFeedbackClient.projectFeedback_GetNumberOfFeedbackFilesForPerson(this.personId)
    },

    async convertProjectDetails (
      projectRetrieved: ProjectGetResponse,
      assignment: AssignmentGetResponse,
      projectFeedback: ProjectFeedbackGetResponse
    ) {
      this.projectName = projectRetrieved.name
      this.assignment = this.convertAssignment(assignment, projectFeedback)
    },

    convertAssignment (assignment: AssignmentGetResponse, projectFeedback: ProjectFeedbackGetResponse) {
      const processedAssignment = {
        assignmentId: assignment.id,
        personId: assignment.personId,
        personName: assignment.personName,
        roleId: assignment.roleId,
        roleName: assignment.roleName,
        projectId: assignment.projectId,
        projectName: assignment.projectName,
        startDateProject: assignment.startDateProject,
        endDateProject: assignment.endDateProject,
        customerName: assignment.customerName,
        customerId: assignment.customerId,
        startDate: (assignment.startDate).substr(0, 10),
        endDate: (assignment.endDate).substr(0, 10),
        projectFeedbackStatus: i18n.t('feedback.isAvailable',
          { submitDate: i18n.d(new Date(projectFeedback.submitDate)) }) as string,
        isEditable: false
      }

      this.personName = assignment.personName
      this.employees.push(processedAssignment)
      return processedAssignment
    },

    async loadProjectDetails () {
      this.siteLoading = true

      this.projectId = parseInt(this.$route.params.projectId)
      this.assignmentId = parseInt(this.$route.params.assignmentId)
      this.personId = parseInt(this.$route.params.personId)
      this.siteLoading = false

      if (!isNaN(this.personId)) {
        await this.loadDataForDownloadAll()
      } else if (!isNaN(this.projectId || this.assignmentId)) {
        await this.loadDetailsForAssignment()
      } else {
        this.projectId = 0
        this.assignmentId = 0
        this.assignmentDetailsLoading = false
      }
    },

    async loadDataForDownloadAll () {
      this.downloadAllForPerson = true
      const person = await this.personClient.person_Get(this.personId)
      this.personName = person.firstName + ' ' + person.lastName
      this.getNumberOfFilesForPerson()
    },

    async loadDetailsForAssignment () {
      this.downloadAllForPerson = false
      this.assignmentDetailsLoading = true

      let projectResponse: ProjectGetResponse
      let assignmentsResponse: AssignmentGetResponse
      let projectFeedbacksResponse: ProjectFeedbackGetResponse

      try {
        projectResponse = await this.projectClient.project_Get(this.projectId)
        assignmentsResponse = await this.assignmentClient.assignment_Get(this.assignmentId)
        projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_Get(this.assignmentId)
      } catch {
        await this.$router.push({ name: 'ListProject' })
        return
      }

      if (projectResponse.errorMessage !== '') {
        this.errorMessage = projectResponse.errorMessage
      } else if (assignmentsResponse.errorMessage !== '') {
        this.errorMessage = assignmentsResponse.errorMessage
      } else if (projectFeedbacksResponse.errorMessage !== '') {
        this.errorMessage = projectFeedbacksResponse.errorMessage
      } else {
        await this.convertProjectDetails(projectResponse, assignmentsResponse, projectFeedbacksResponse)
      }

      this.assignmentDetailsLoading = false
    }
  },

  async beforeMount () {
    await this.loadProjectDetails()
  },

  created () {
    // watching the parameters so that the page will load correctly when changing parameters
    this.$watch(
      () => this.$route.params,
      async (toParams, previousParams) => {
        if (toParams !== previousParams) {
          await this.loadProjectDetails()
        }
      }
    )
  }

})
