













































import Vue from 'vue'
import { CustomerClient, PersonClient } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'Customer',

  data: () => ({
    name: '',
    kvId: 0,
    errorMessage: '',
    validation: new Validation(),
    listOfKv: Array<Record<string, unknown>>(),
    kvLoading: true,
    personClient: new PersonClient(),
    customerClient: new CustomerClient()
  }),

  computed: {
    formData () {
      return [this.name, this.kvId]
    }
  },

  watch: {
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    async loadListOfKv () {
      this.kvLoading = true

      const personNamesResponse = await this.personClient.person_GetAllNames()
      if (personNamesResponse.errorMessage === '') {
        const names = personNamesResponse.names
        this.listOfKv = names.map(person => { return { value: person.id, text: person.name } })
      } else {
        const customerLocalizedText = i18n.t('customer')
        const kvLocalizedText = i18n.t('customerRep')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: customerLocalizedText, listOf: kvLocalizedText }) as string
      }

      this.kvLoading = false
    },
    /**
     * Performs plausibility check and adds customer to database, if passed.
     */
    async createCustomer () {
      const refForm = this.$refs.customerForm as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        return
      }
      const customerResponse = await this.customerClient.customer_Post({
        name: this.name.trim(),
        kvId: this.kvId
      })
      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = customerResponse.errorMessage
      if (customerResponse.errorMessage) {
        return
      }

      // go back to customer list
      // params will be used for user feedback
      await this.$router.push({
        name: 'ListCustomerWithParams',
        params: {
          createdCustomerName: this.name,
          createdKvName: this.listOfKv.find(kv => kv.value === this.kvId)!.text as string
        }
      })
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      return (this.validation.notempty(this.name) === true &&
        this.validation.notNumberNull(this.kvId) === true)
    }
  },

  async beforeMount () {
    await this.loadListOfKv()
  }

})

