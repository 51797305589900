








































import Vue from 'vue'
import { SelfFeedbackClient, SelfFeedbackGetResponse } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'AddSelfFeedback',

  data: () => ({
    content: '',
    errorMessage: '',
    editMessage: '',
    validation: new Validation(),
    feedbackCreated: false,
    feedbackLoading: false,
    selfFeedbackClient: new SelfFeedbackClient()
  }),

  watch: {
    content: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    async createSelfFeedback () {
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        this.feedbackCreated = false
        return
      }
      const personResponse = await this.selfFeedbackClient.selfFeedback_Put(({
        content: this.content.trim()
      }))
      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = personResponse.errorMessage
      if (personResponse.errorMessage) {
        this.feedbackCreated = false
        return
      }
      this.feedbackCreated = true
    },

    checkForm: function () {
      return (this.validation.notempty(this.content) === true)
    },

    decisionContentAddOrEdit (selfFeedbackRetrieved: SelfFeedbackGetResponse) {
      if (selfFeedbackRetrieved.errorMessage !== '') {
        this.errorMessage = selfFeedbackRetrieved.errorMessage
      } else if (selfFeedbackRetrieved.hasContent) {
        const submitDate = i18n.d(new Date(selfFeedbackRetrieved.submitDate), 'short')
        this.editMessage = this.$i18n.t('addSelfFeedbackPage.editMessage', { submitDate: submitDate }) as string
      } else {
        this.editMessage = ''
      }
    },

    async loadSelfFeedback () {
      this.feedbackLoading = true
      this.decisionContentAddOrEdit(await this.selfFeedbackClient.selfFeedback_Get())
      this.feedbackLoading = false
    }
  },

  async beforeMount () {
    await this.loadSelfFeedback()
  }
})
