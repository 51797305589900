










































































































































































































































import Vue from 'vue'
import {
  PersonClient,
  AssignmentClient,
  ProjectFeedbackClient,
  PersonGetResponse,
  AssignmentGetResponse,
  FrontendAction,
  AssignmentGetAllResponse,
  ProjectFeedbackGetAllResponse,
  ProjectFeedbackFile,
  ProjectGetResponse,
  CustomerGetResponse, ProjectClient, CustomerClient
} from '@/api/clients'
import EditDialogAssignment from '@/component/EditDialogAssignment.vue'
import i18n from '@/i18n'
import { Dictionary } from 'vue-router/types/router'
import { EnumConverter } from '@/methods/EnumConverter'
import { isActionAvailable } from '@/api/availableActions'
import { DisplayAssignment } from '@/api/frontendOnlyTypes'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ViewPersonDetails',

  components: {
    IconWithTooltip,
    EditDialogAssignment
  },

  data: () => ({
    personName: '',
    mail: '',
    persNo: '',
    departmentName: '',
    jobLevel: '',
    pvName: '',
    personId: 0,
    isAdmin: false,
    isPv: false,
    noPv: false,
    projectFeedbackStatus: '',
    assignmentId: 0,
    dialogEdit: false,
    errorMessage: '',
    showSuccessMessage: false,
    toEditAssignment: {},
    dialog: false,
    selectedAssignmentId: 0,
    selectedFile: {} as File,
    inputKey: 0,
    selectedItem: {} as DisplayAssignment,
    selectedFileDialog: false,
    fileUploadedDialog: false,
    files: [] as ProjectFeedbackFile[],
    selectedDownloadFile: {} as ProjectFeedbackFile,

    headers: [
      { value: 'projectName', text: i18n.t('projectName') as string },
      { value: 'roleName', text: i18n.t('projectRole') as string },
      { value: 'startDate', text: i18n.t('startDateAssignment') as string },
      { value: 'endDate', text: i18n.t('endDateAssignment') as string },
      { value: 'projectFeedbackStatus', text: i18n.t('feedback.projectFeedbackStatus') as string },
      { value: 'action', text: i18n.t('editing') as string, align: 'center', sortable: false },
      { value: 'downloadProjectFeedback', text: i18n.t('personDetailsView.downloadFile') as string, align: 'center', sortable: false }
    ],

    assignments: Array<DisplayAssignment>(),
    assignmentsHeaderText: i18n.t('listPersonPage.assignmentsHeader'),
    personDetailsLoading: true,
    enumConverter: new EnumConverter(),
    frontendActions: FrontendAction,
    personClient: new PersonClient(),
    assignmentClient: new AssignmentClient(),
    projectFeedbackClient: new ProjectFeedbackClient(),

    selfIsPl: false,
    selfIsKv: false,
    plProjects: Array<ProjectGetResponse>(),
    kvCustomers: Array<CustomerGetResponse>(),

    plProjectHeaders: [
      { value: 'name', text: i18n.t('name') as string, sortable: true },
      { value: 'customerName', text: i18n.t('customer') as string },
      { value: 'startDate', text: i18n.t('startDate') as string },
      { value: 'endDate', text: i18n.t('endDate') as string }
    ],

    kvCustomersHeaders: [
      { value: 'name', text: i18n.t('name') as string, sortable: true },
      { value: 'kvName', text: i18n.t('customerRep') as string }
    ],

    projectClient: new ProjectClient(),
    customerClient: new CustomerClient()
  }),

  methods: {
    async loadData () {
      const plData = await this.projectClient.project_TryGetAllForPl(this.personId)
      this.selfIsPl = plData.selfIsPl

      if (!plData.errorMessage && plData.selfIsPl) {
        this.plProjects = plData.projects
      }

      const kvData = await this.customerClient.customer_TryGetAllForKv(this.personId)
      this.selfIsKv = kvData.selfIsKv

      if (!kvData.errorMessage && kvData.selfIsKv) {
        this.kvCustomers = kvData.customers
      }
    },

    async selectFile (file: File, item:DisplayAssignment) {
      this.dialog = true
      this.selectedAssignmentId = item.assignmentId
      this.selectedFile = file
    },

    async showFiles (item:DisplayAssignment) {
      this.selectedItem = item
      this.selectedDownloadFile = {} as ProjectFeedbackFile
      var projectFeedbackResponse = await this.projectFeedbackClient.projectFeedback_Get(item.assignmentId)
      this.files = projectFeedbackResponse.projectFeedbackFiles
    },

    async downloadFile () {
      if (this.selectedDownloadFile.id !== undefined) {
        const projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_DownloadFileForUser(this.selectedDownloadFile.id)
        const url = window.URL.createObjectURL(projectFeedbacksResponse.data)
        const link = document.createElement('a')
        link.href = url
        const fileName: string = this.selectedDownloadFile.fileName + '.' + this.selectedDownloadFile.fileType
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        this.selectedFileDialog = false
      } else {
        console.log('please select a file!')
      }
    },
    isActionAvailable,

    async convertPersonDetails (
      personRetrieved: PersonGetResponse,
      assignmentsRetrieved: Array<AssignmentGetResponse>,
      projectFeedbacksRetrieved?: Dictionary<string>
    ) {
      this.convertPersonDetailsNoAssignment(personRetrieved)

      this.assignments = Array<DisplayAssignment>()
      assignmentsRetrieved.forEach((assignment: AssignmentGetResponse) => {
        this.addAssignments(assignment, projectFeedbacksRetrieved)
        // console.log("AS '" + assignment.id + "'\n")
        // console.log("PR '" + projectFeedbacksRetrieved +"'")
      })
    },

    convertPersonDetailsNoAssignment (personRetrieved: PersonGetResponse) {
      this.mail = personRetrieved.mail
      this.personName = personRetrieved.firstName + ' ' + personRetrieved.lastName
      this.persNo = personRetrieved.persNo
      this.departmentName = personRetrieved.departmentName
      this.jobLevel = this.enumConverter.toLocalizedTextForJobLevels(personRetrieved.jobLevel)
      this.isAdmin = personRetrieved.isAdmin
      this.isPv = personRetrieved.isPv
      this.pvName = personRetrieved.pvName
      this.noPv = this.pvName === ''
    },

    async addAssignments (assignment: AssignmentGetResponse, projectFeedbacksRetrieved?: Dictionary<string>) {
      const processedAssignment = {
        assignmentId: assignment.id,
        personId: assignment.personId,
        personName: this.personName,
        roleId: assignment.roleId,
        roleName: assignment.roleName,
        projectId: assignment.projectId,
        projectName: assignment.projectName,
        startDateProject: '',
        endDateProject: '',
        customerName: assignment.customerName,
        customerId: assignment.customerId,
        startDate: (assignment.startDate).substr(0, 10),
        endDate: (assignment.endDate).substr(0, 10),
        projectFeedbackStatus: i18n.t('feedback.isNotAvailable') as string,
        isEditable: assignment.isEditable
      }

      if (projectFeedbacksRetrieved === undefined) {
        processedAssignment.projectFeedbackStatus = i18n.t('feedback.isUnknown') as string
        this.assignments.push(processedAssignment)
        return
      }

      if (assignment.id in projectFeedbacksRetrieved) {
        processedAssignment.projectFeedbackStatus = i18n.t('feedback.isAvailable',
          { submitDate: i18n.d(new Date(projectFeedbacksRetrieved[assignment.id])) }) as string
      }
      this.assignments.push(processedAssignment)
    },

    async loadPersonDetails () {
      this.personDetailsLoading = true

      this.personId = parseInt(this.$route.params.id)
      if (isNaN(this.personId)) {
        await this.$router.push({ name: 'ListPerson' })
        return
      }

      let personResponse: PersonGetResponse
      let assignmentsResponse: AssignmentGetAllResponse
      let projectFeedbacksResponse: ProjectFeedbackGetAllResponse

      try {
        personResponse = await this.personClient.person_Get(this.personId)
        assignmentsResponse = await this.assignmentClient.assignment_GetAllForPerson(this.personId)
        projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_GetAllForPerson(this.personId)
      } catch {
        await this.$router.push({ name: 'ListPerson' })
        return
      }

      if (personResponse.errorMessage !== '') {
        this.errorMessage = personResponse.errorMessage
      } else if (assignmentsResponse.errorMessage !== '') {
        this.convertPersonDetailsNoAssignment(personResponse)
        this.assignmentsHeaderText = i18n.t('listPersonPage.assignmentsHeaderNoData')
      } else if (projectFeedbacksResponse.errorMessage !== '') {
        await this.convertPersonDetails(personResponse, assignmentsResponse.assignments)
      } else {
        await this.convertPersonDetails(personResponse, assignmentsResponse.assignments, projectFeedbacksResponse.projectFeedbacks)
      }

      this.personDetailsLoading = false
    },

    async validationDeleteAssignment (toDeleteAssignment: DisplayAssignment) {
      this.showSuccessMessage = false
      this.errorMessage = ''
      if (confirm(this.$i18n.t('detailsView.deleteAssignmentMessage',
        {
          personName: toDeleteAssignment.personName,
          projectName: toDeleteAssignment.projectName
        }) as string)
      ) {
        await this.deleteAssignment(toDeleteAssignment)
        await this.loadPersonDetails()
      }
    },

    async deleteAssignment (toDeleteAssignment: DisplayAssignment) {
      const assignmentResponse = await this.assignmentClient.assignment_Delete(toDeleteAssignment.assignmentId, toDeleteAssignment.customerId)
      this.errorMessage = assignmentResponse.errorMessage
    },

    openEditAssignment: function (editedAssignment: DisplayAssignment) {
      this.showSuccessMessage = false
      this.dialogEdit = true
      this.errorMessage = ''
      this.toEditAssignment = editedAssignment
    },

    async closeEditAssignment () {
      this.dialogEdit = false
      await this.loadPersonDetails()
    },

    editAssignmentSuccessful: function () {
      this.showSuccessMessage = true
    },

    async openDetailPageProject (item: ProjectGetResponse) {
      await this.$router.push({ name: 'ViewProjectDetails', params: { id: String(item.id) } })
    },

    async openDetailPageCustomer () {
      await this.$router.push({ name: 'ListCustomer' })
    }

  },

  async beforeMount () {
    await this.loadPersonDetails()
    await this.loadData()
  },

  created () {
    // watching the parameters so that the page will load correctly if user goes to their own detail page (which would be the same route with a different parameter
    this.$watch(
      () => this.$route.params,
      async (toParams, previousParams) => {
        if (toParams !== previousParams) {
          await this.loadPersonDetails()
          await this.loadData()
        }
      }
    )
  }
})
