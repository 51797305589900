


























































































































































import { Validation } from '@/methods/Validation'
import { AssignmentClient, AssignmentRoleClient } from '@/api/clients'
import Vue from 'vue'
import { EnumConverter } from '@/methods/EnumConverter'
import { DateConverter } from '@/methods/DateConverter'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'EditDialogAssignment',

  props: {
    showDialog: Boolean,
    assignmentId: Number,
    personId: Number,
    personName: String,
    roleId: Number,
    projectId: Number,
    projectName: String,
    startDateProject: String,
    endDateProject: String,
    customerName: String,
    customerId: Number,
    startDate: String,
    endDate: String
  },

  data: () => ({
    errorMessage: '',
    validation: new Validation(),
    enumConverter: new EnumConverter(),
    dateConverter: new DateConverter(),
    listOfAssignmentRoles: Array<Record<string, unknown>>(),
    assignmentRolesLoading: true,
    toEditAssignment: {
      assignmentId: 0,
      personId: 0,
      personName: '',
      roleId: 0,
      projectId: 0,
      projectName: '',
      customerName: '',
      customerId: 0,
      startDatePerson: '',
      startDateFormatted: '',
      endDatePerson: '',
      endDateFormatted: '',
      startDateProject: '',
      endDateProject: '',
      startDateMenu: false,
      endDateMenu: false
    },
    assignmentClient: new AssignmentClient(),
    assignmentRoleClient: new AssignmentRoleClient()
  }),

  watch: {
    showDialog: {
      handler: function (newVal) {
        if (newVal === false) {
          return
        }
        this.toEditAssignment = {
          assignmentId: this.assignmentId,
          personId: this.personId,
          personName: this.personName,
          roleId: this.roleId,
          projectId: this.projectId,
          projectName: this.projectName,
          customerName: this.customerName,
          customerId: this.customerId,
          startDatePerson: this.startDate,
          endDatePerson: this.endDate,
          startDateFormatted: this.dateConverter.formatDate(this.startDate),
          endDateFormatted: this.dateConverter.formatDate(this.endDate),
          startDateProject: this.startDateProject,
          endDateProject: this.endDateProject,
          startDateMenu: false,
          endDateMenu: false
        }
      },
      immediate: true
    },

    'toEditAssignment.startDatePerson' () {
      this.toEditAssignment.startDateFormatted = this.dateConverter.formatDate(this.toEditAssignment.startDatePerson)
      this.errorMessage = ''
    },

    'toEditAssignment.endDatePerson' () {
      this.toEditAssignment.endDateFormatted = this.dateConverter.formatDate(this.toEditAssignment.endDatePerson)
      this.errorMessage = ''
    },

    // somehow, this does not work with a computed property as in most other files
    'toEditAssignment.role' () {
      this.errorMessage = ''
    }
  },

  methods: {
    async loadListOfAssignmentRole () {
      this.assignmentRolesLoading = true

      const assignmentRolesResponse = await this.assignmentRoleClient.assignmentRole_GetAll()
      if (assignmentRolesResponse.errorMessage === '') {
        const assignmentRoles = assignmentRolesResponse.assignmentRoles
        this.listOfAssignmentRoles = assignmentRoles.map(assignmentRole => { return { value: assignmentRole.id, text: assignmentRole.name } })
      } else {
        const assignmentLocalizedText = i18n.t('assignment')
        const AssignmentRolesLocalizedText = i18n.t('assignmentRole')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: assignmentLocalizedText, listOf: AssignmentRolesLocalizedText }) as string
      }

      this.assignmentRolesLoading = false
    },

    async editAssignment () {
      if (!this.checkForm()) {
        return
      }
      const assignmentResponse = await this.assignmentClient.assignment_Edit({
        id: this.toEditAssignment.assignmentId,
        personId: this.toEditAssignment.personId,
        projectId: this.toEditAssignment.projectId,
        startDate: this.toEditAssignment.startDatePerson,
        endDate: this.toEditAssignment.endDatePerson,
        roleId: this.toEditAssignment.roleId
      }, this.toEditAssignment.customerId)
      this.errorMessage = assignmentResponse.errorMessage
      if (assignmentResponse.errorMessage) {
        return
      }
      this.$emit('success')
    },

    checkForm: function () {
      if (!this.validation.validDates(this.toEditAssignment.startDatePerson, this.toEditAssignment.endDatePerson)) {
        this.errorMessage = this.$i18n.t('error.startBeforeEndDate') as string
        return false
      }
      this.errorMessage = ''
      return this.validation.notNumberNull(this.toEditAssignment.roleId) === true
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.editAssignment()
      if (this.errorMessage === '' && this.checkForm()) {
        this.close()
      }
    }
  },

  async beforeMount () {
    await this.loadListOfAssignmentRole()
  }
})
