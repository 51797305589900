import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import ErrorPage from '../views/ErrorPage.vue'
import AddPerson from '@/views/AddPerson.vue'
import AddProject from '@/views/AddProject.vue'
import ListPerson from '@/views/ListPerson.vue'
import ListDownloadFiles from '@/views/ListDownloadFiles.vue'
import AddCustomer from '@/views/AddCustomer.vue'
import ListCustomer from '@/views/ListCustomer.vue'
import ListProject from '@/views/ListProject.vue'
import AddSelfFeedback from '@/views/AddSelfFeedback.vue'
import AddProjectFeedback from '@/views/AddProjectFeedback.vue'
import ViewProjectDetails from '@/views/ViewProjectDetails.vue'
import AddAssignment from '@/views/AddAssignment.vue'
import ViewPersonDetails from '@/views/ViewPersonDetails.vue'
import Login from '@/views/Login.vue'
import ChangeUserPw from '@/views/ChangeUserPw.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ErrorPage',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/ChangeUserPw',
    name: 'ChangeUserPw',
    component: ChangeUserPw
  },
  {
    path: '/AddPerson',
    name: 'AddPerson',
    component: AddPerson
  },
  {
    path: '/AddSelfFeedback',
    name: 'AddSelfFeedback',
    component: AddSelfFeedback
  },
  {
    path: '/ListPerson',
    name: 'ListPerson',
    component: ListPerson
  },
  {
    path: '/ListDownloadFiles/:projectId/:assignmentId',
    name: 'ListDownloadFiles',
    component: ListDownloadFiles
  },
  {
    path: '/ListDownloadFiles/:personId',
    name: 'ListAllDownloadFilesForPerson',
    component: ListDownloadFiles
  },
  {
    path: '/ListPerson/:createdPersonName/:createdPvName',
    name: 'ListPersonWithParams',
    component: ListPerson
  },
  {
    path: '/ViewPersonDetails/:id',
    name: 'ViewPersonDetails',
    component: ViewPersonDetails
  },
  {
    path: '/AddProject',
    name: 'AddProject',
    component: AddProject
  },
  {
    path: '/AddProjectFeedback/:projectId/:assignmentId',
    name: 'AddProjectFeedback',
    component: AddProjectFeedback
  },
  {
    path: '/ListProject',
    name: 'ListProject',
    component: ListProject
  },
  {
    path: '/ListProject/:createdCustomerName/:createdProjectName/:createdPlName',
    name: 'ListProjectWithParamsCreatedProject',
    component: ListProject
  },
  {
    path: '/ListProject/:createdAssignmentPerson/:createdAssignmentProject/:createdAssignmentRole',
    name: 'ListProjectWithParamsCreatedAssignment',
    component: ListProject
  },
  {
    path: '/ViewProjectDetails/:id',
    name: 'ViewProjectDetails',
    component: ViewProjectDetails
  },
  {
    path: '/ViewProjectDetails/:id/:feedbackCreated',
    name: 'ViewProjectDetailsWithParamsFeedback',
    component: ViewProjectDetails
  },
  {
    path: '/ViewProjectDetails/:id/:createdAssignmentPerson/:createdAssignmentProject/:createdAssignmentRole',
    name: 'ViewProjectDetailsWithParamsCreatedAssigment',
    component: ViewProjectDetails
  },
  {
    path: '/AddCustomer',
    name: 'AddCustomer',
    component: AddCustomer
  },
  {
    path: '/ListCustomer',
    name: 'ListCustomer',
    component: ListCustomer
  },
  {
    path: '/ListCustomer/:createdCustomerName/:createdKvName',
    name: 'ListCustomerWithParams',
    component: ListCustomer
  },
  {
    path: '/AddAssignment/:previousPage',
    name: 'AddAssignment',
    component: AddAssignment
  }
]

const router = new VueRouter({
  routes
})

export default router
