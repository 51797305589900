




































































































import Vue from 'vue'
import { CustomerClient, CustomerGetResponse, FrontendAction } from '@/api/clients'
import EditDialogCustomer from '@/component/EditDialogCustomer.vue'
import i18n from '@/i18n'
import { isActionAvailable } from '@/api/availableActions'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ListCustomer',

  components: {
    IconWithTooltip,
    EditDialogCustomer
  },

  data: () => ({
    dialogEdit: false,
    customerCreatedDialog: false,
    errorMessage: '',
    showSuccessMessage: false,
    toEditCustomer: {} as CustomerGetResponse,

    headers: [
      { value: 'name', text: i18n.t('name') as string, sortable: true },
      { value: 'kvName', text: i18n.t('customerRep') as string },
      { value: 'action', text: i18n.t('editing') as string, align: 'center', sortable: false }
    ],

    search: '',
    customers: Array<CustomerGetResponse>(),
    listOfKv: Array<string>(),
    customersLoading: true,
    frontendActions: FrontendAction,
    customerClient: new CustomerClient(),
    createdCustomerName: '',
    createdKvName: ''
  }),

  watch: {
    async customerCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ListCustomer' })
      }
    }
  },

  methods: {
    isActionAvailable,

    async loadCustomers () {
      this.customersLoading = true

      const customerResponse = await this.customerClient.customer_GetAll()
      this.errorMessage = customerResponse.errorMessage
      this.customers = customerResponse.customers

      this.customersLoading = false
    },

    async validationDeleteCustomer (toDeleteCustomer: CustomerGetResponse) {
      this.showSuccessMessage = false
      this.errorMessage = ''
      if (confirm(this.$i18n.t('listCustomerPage.deleteMessage', { name: toDeleteCustomer.name }) as string)) {
        await this.deleteCustomer(toDeleteCustomer)

        if (this.errorMessage === '') {
          await this.loadCustomers()
        }
      }
    },

    async deleteCustomer (toDeleteCustomer: CustomerGetResponse) {
      const customerResponse = await this.customerClient.customer_Delete(toDeleteCustomer.id)
      this.errorMessage = customerResponse.errorMessage
    },

    openEditCustomer: function (editedCustomer: CustomerGetResponse) {
      this.showSuccessMessage = false
      this.dialogEdit = true
      this.errorMessage = ''
      this.toEditCustomer = editedCustomer
    },

    async closeEditCustomer () {
      this.dialogEdit = false
      await this.loadCustomers()
    },

    editCustomerSuccessful: function () {
      this.showSuccessMessage = true
    }
  },

  async beforeMount () {
    await this.loadCustomers()

    this.createdCustomerName = this.$route.params.createdCustomerName
    this.createdKvName = this.$route.params.createdKvName
    if (!(this.createdCustomerName === undefined && this.createdKvName === undefined)) {
      this.customerCreatedDialog = true
    }
  }
})

