
































































import Vue from 'vue'
import { Validation } from '@/methods/Validation'
import { AuthClient, AuthResponse } from '@/api/clients'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'ChangeUserPw',

  data: () => ({
    validation: new Validation(),
    firstLogin: false,
    mail: '',
    oldPassword: '',
    newPassword: '',
    repeatedNewPassword: '',
    showOldPw: false,
    showNewPw: false,
    showRepeatedNewPw: false,
    errorMessage: '',
    successMessage: '',
    authClient: new AuthClient()
  }),

  computed: {
    formData () {
      return [this.oldPassword, this.newPassword, this.repeatedNewPassword]
    }
  },

  watch: {
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    async submitNewPw () {
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      if (!this.checkForm()) {
        return
      }
      const personResponse = await this.authClient.auth_ChangePassword(this.oldPassword, this.newPassword)

      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = personResponse.errorMessage
      if (personResponse.errorMessage === '') {
        this.successMessage = i18n.t('changeUserPwPage.successfulChange') as string

        const user: AuthResponse = JSON.parse(localStorage.getItem('user') as string)
        if (user.autogeneratedPw) {
          user.autogeneratedPw = false
          localStorage.setItem('user', JSON.stringify(user))

          await this.changedAutogenPw()
        }
      }
    },

    /**
     * Sets availableActions and redirect after, so that all buttons are available immediately.
     */
    async changedAutogenPw () {
      const response = await this.authClient.auth_GetAllowedFrontendActions()

      if (response.errorMessage) {
        this.errorMessage = i18n.t('error.redirect') as string
      } else {
        localStorage.setItem('availableActions', JSON.stringify(response.actions))
        await this.$router.push({ name: 'Home' })
      }
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      return (this.validation.notempty(this.newPassword) === true &&
        this.validation.password(this.newPassword) === true &&
        this.validation.differentPassword(this.newPassword, this.oldPassword) === true &&
        this.validation.equalPassword(this.newPassword, this.repeatedNewPassword) === true)
    }
  },

  beforeMount () {
    if (typeof this.$route.query.firstLogin === 'string' && this.$route.query.firstLogin === 'true') {
      this.firstLogin = true
    }
  }
})
