var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('v-container',[_c('v-row',{staticClass:"rowWithVirtualCol"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('missingFeedback.missingProjectFeedback')))])]),_c('v-row',{staticClass:"pageRow rowWithVirtualCol"},[_c('v-data-table',{staticClass:"listViewTable",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.missingFeedbacks,"item-key":"projectId","show-expand":"","footer-props":{
          disableItemsPerPage: true
            }},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.startDate), 'short')))])]}},{key:"item.endDate",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.endDate), 'short')))])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-col',{staticClass:"ma-3"},[_c('v-row',[_vm._v(" "+_vm._s(_vm.$t('missingFeedback.missingProjectFeedbackDetails'))+" ")]),_c('v-row',[_c('table',{staticStyle:{"border-spacing":"10px"}},_vm._l((item.missingFeedbackMembers),function(member){return _c('tr',{key:member.personId + member.startTime},[_c('td',[_vm._v(" "+_vm._s(member.fullName)+" ")]),_c('td',[_vm._v(" "+_vm._s(member.roleName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('dateFrom'))+" "+_vm._s(_vm.$d(new Date(member.startTime), 'short'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('dateTo'))+" "+_vm._s(_vm.$d(new Date(member.endTime), 'short'))+" ")])])}),0)])],1)],1)]}}])})],1),(_vm.errorMessage !== '' || _vm.notifySuccess)?_c('v-row',{staticClass:"pageRow rowWithVirtualCol"},[(_vm.errorMessage !== '')?_c('span',{staticClass:"serverError"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" "+_vm._s(_vm.$tc('missingFeedback.numMailsSentFailed', _vm.numberNotified, { mails: _vm.numberNotified }))+" ")]):(_vm.notifySuccess)?_c('span',{staticClass:"serverError"},[_vm._v(" "+_vm._s(_vm.$tc('missingFeedback.numMailsSentSuccess', _vm.numberNotified, { mails: _vm.numberNotified }))+" ")]):_vm._e()]):_vm._e(),_c('v-row',{staticClass:"rowWithVirtualCol"},[_c('v-spacer'),_c('v-btn',{staticClass:"defaultButton defaultLeftMargin",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{staticClass:"defaultButton defaultLeftMargin",attrs:{"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('missingFeedback.sendReminderMail'))+" ")]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.sendingMails),expression:"sendingMails"}],staticClass:"progressCycle",attrs:{"indeterminate":"","color":"#841439"}})],1),_c('v-row',{staticClass:"rowWithVirtualCol"},[_c('v-spacer'),(_vm.sendingMails)?_c('span',[_vm._v(_vm._s(_vm.$t('missingFeedback.sendingInProgress')))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }