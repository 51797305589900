import { FrontendAction, ProjectClient, ProjectCanGiveFeedbackGetResponse } from '@/api/clients'

/**
 * Returns true, if the given action / button is available to the logged in user, according to the
 * array that is stored locally.
 */
export function isActionAvailable (action : FrontendAction) : boolean {
  let actions = JSON.parse(localStorage.getItem('availableActions') as string)
  actions ??= []

  return actions.includes(action)
}

/**
 * Returns true, if the FrontendAction.ViewOwnProjectFeedback is available to the logged in user, according to the
 * array that is stored locally.
 * Also verifies that the currently logged in user is able to give feedback to assignments of the project.
 */
export async function isProjectFeedbackActionAvailable (projectId : number) : Promise<boolean> {
  if (!isActionAvailable(FrontendAction.ViewOwnProjectFeedback)) {
    return false
  }

  const client = new ProjectClient()
  let response: ProjectCanGiveFeedbackGetResponse

  try {
    response = await client.project_CanGiveFeedbackTo(projectId)
  } catch {
    return false
  }

  return !response.errorMessage && response.canGiveFeedback
}
