import moment from 'moment/moment'

export class DateConverter {
  /**
   * Formats a date to German format
   * @param date: the date to format.
   * @returns string of the formatted date.
   */
  formatDate (date: string): string {
    moment.locale('de-de')
    if (!date) {
      return ''
    }
    return date ? moment(date).format('L') : ''
  }

  /**
   * Parses a date in German format to standard format
   * @param date: the date to parse.
   * @returns parsed date as string.
   */
  parseDate (date: string): string {
    if (!date) {
      return ''
    }

    const [day, month, year] = date.split('.')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }
}
