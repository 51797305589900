




























































































import Vue from 'vue'
import { SingleMissingProjectFeedback, ProjectFeedbackClient } from '@/api/clients'
import i18n from '@/i18n'
import { EnumConverter } from '@/methods/EnumConverter'

export default Vue.extend({
  name: 'ListDialogMissingProjectFeedback',

  props: {
    showDialog: Boolean
  },

  watch: {
    showDialog: {
      async handler (newVal) {
        if (newVal === false) {
          return
        }

        this.errorMessage = ''
        this.numberNotified = 0
        this.notifySuccess = false
        await this.loadData()
      },
      immediate: true
    }
  },

  data: () => ({
    errorMessage: '',
    headers: [
      { value: 'name', text: i18n.t('project') as string, sortable: true },
      { value: 'projectLead', text: i18n.t('projectLead') as string },
      { value: 'customer', text: i18n.t('customer') as string },
      { value: 'startDate', text: i18n.t('startDate') as string },
      { value: 'endDate', text: i18n.t('endDate') as string },
      { value: 'data-table-expand', text: '' }
    ],
    missingFeedbacks: [] as SingleMissingProjectFeedback[],
    numberNotified: 0,
    notifySuccess: false,
    sendingMails: false,
    enumConverter: new EnumConverter(),
    projectFeedbackClient: new ProjectFeedbackClient()
  }),

  methods: {
    async loadData () {
      const response = await this.projectFeedbackClient.projectFeedback_GetMissingProjectFeedback()
      this.errorMessage = response.errorMessage
      this.missingFeedbacks = response.singleMissingProjectFeedbacks
    },

    async sendSelfFeedbackReminder () {
      this.sendingMails = true

      const selfFeedbackResponse = await this.projectFeedbackClient.projectFeedback_NotifyMissingProjectFeedback()
      this.errorMessage = selfFeedbackResponse.errorMessage
      this.notifySuccess = selfFeedbackResponse.errorMessage !== ''
      this.numberNotified = selfFeedbackResponse.personIds.length

      this.sendingMails = false
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.sendSelfFeedbackReminder()
      if (this.errorMessage === '') {
        this.close()
      }
    }
  }
})
