










































































































































































































































































































import Vue from 'vue'
import {
  AssignmentClient,
  ProjectClient,
  ProjectGetResponse,
  ProjectFeedbackClient,
  AssignmentGetResponse,
  FrontendAction,
  AssignmentGetAllResponse,
  ProjectFeedbackGetAllResponse, ProjectFeedbackFile
} from '@/api/clients'
import EditDialogAssignment from '@/component/EditDialogAssignment.vue'
import i18n from '@/i18n'
import { Dictionary } from 'vue-router/types/router'
import { EnumConverter } from '@/methods/EnumConverter'
import { isActionAvailable, isProjectFeedbackActionAvailable } from '@/api/availableActions'
import { DisplayAssignment } from '@/api/frontendOnlyTypes'
import IconWithTooltip from '@/component/IconWithTooltip.vue'

export default Vue.extend({
  name: 'ViewProjectDetails',

  components: {
    IconWithTooltip,
    EditDialogAssignment
  },

  data: () => ({
    projectName: '',
    personName: '',
    plName: '',
    customerName: '',
    customerId: 0,
    startDateProject: '',
    endDateProject: '',
    startDate: '',
    endDate: '',
    isEditable: false,
    projectPeriod: '',
    projectId: 0,
    projectFeedbackStatus: '',
    dialogEdit: false,
    errorMessage: '',
    showSuccessMessageEdit: false,
    showSuccessMessageDelete: false,
    toEditAssignment: {},
    dialog: false,
    accepted: false,
    selectedAssignmentId: 0,
    selectedFile: {} as File,
    inputKey: 0,
    selectedItem: {} as DisplayAssignment,
    selectedFileDialog: false,
    fileUploadedDialog: false,
    files: [] as ProjectFeedbackFile[],
    selectedDownloadFile: {} as ProjectFeedbackFile,
    fileDownload: true,

    headers: [
      { value: 'personName', text: i18n.t('employee') as string },
      { value: 'roleName', text: i18n.t('projectRole') as string },
      { value: 'startDate', text: i18n.t('startDateAssignment') as string },
      { value: 'endDate', text: i18n.t('endDateAssignment') as string },
      { value: 'projectFeedbackStatus', text: i18n.t('feedback.projectFeedbackStatus') as string },
      { value: 'action', text: i18n.t('editing') as string, align: 'center', sortable: false },
      { value: 'feedback', text: i18n.t('feedback.projectFeedback') as string, align: 'center', sortable: false }
    ],

    assignments: Array<DisplayAssignment>(),
    projectDetailsLoading: true,
    canGiveProjectFeedback: false,
    enumConverter: new EnumConverter(),
    frontendActions: FrontendAction,
    projectClient: new ProjectClient(),
    assignmentClient: new AssignmentClient(),
    projectFeedbackClient: new ProjectFeedbackClient(),
    feedbackCreatedDialog: false,
    assignmentCreatedDialog: false,
    createdAssignmentPerson: '',
    createdAssignmentProject: '',
    createdAssignmentRole: ''
  }),

  watch: {
    async feedbackCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ViewProjectDetails', params: { id: String(this.projectId) } })
      }
    },

    async assignmentCreatedDialog (dialogShown) {
      if (!dialogShown) {
        await this.$router.push({ name: 'ViewProjectDetails', params: { id: String(this.projectId) } })
      }
    }
  },

  methods: {
    async selectFile (file: File, item:DisplayAssignment) {
      if (file.type === 'application/pdf') {
        this.dialog = true
        this.selectedAssignmentId = item.assignmentId
        this.selectedFile = file
      } else {
        this.errorMessage = i18n.t('feedback.fileUploadWrongFormat') as string
      }
    },

    async showFiles (item:DisplayAssignment) {
      this.selectedItem = item
      this.selectedDownloadFile = {} as ProjectFeedbackFile
      var projectFeedbackResponse = await this.projectFeedbackClient.projectFeedback_Get(item.assignmentId)
      this.files = projectFeedbackResponse.projectFeedbackFiles != null ? projectFeedbackResponse.projectFeedbackFiles : []
    },

    async downloadFile () {
      if (this.selectedDownloadFile.id !== undefined) {
        const projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_DownloadFileForUser(this.selectedDownloadFile.id)
        const url = window.URL.createObjectURL(projectFeedbacksResponse.data)
        const link = document.createElement('a')
        link.href = url
        const fileName: string = this.selectedDownloadFile.fileName + '.' + this.selectedDownloadFile.fileType
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        this.selectedFileDialog = false
      }
    },

    async deleteFile () {
      if (this.selectedDownloadFile.id !== undefined) {
        this.selectedFileDialog = false
        if (confirm(this.$i18n.t('projectDetailsView.deleteFileMessage', {
          fileName: this.selectedDownloadFile.fileName
        }) as string)
        ) {
          const response = await this.projectFeedbackClient.projectFeedback_Delete(this.selectedDownloadFile.id)
          if (response.errorMessage !== '') {
            this.errorMessage = response.errorMessage
          }
          this.showSuccessMessageDelete = true
        }
      }
    },

    async downloadFileFeedbackGuide (name) {
      const projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_DownloadFiles(name)
      const url = window.URL.createObjectURL(projectFeedbacksResponse.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      this.selectedFileDialog = false
    },
    isActionAvailable,
    openInNewTab (url: string) {
      window.open(url, '_blank', 'noreferrer')
    },
    async accept () {
      if (this.accepted) {
        this.dialog = false
        const personResponse = await this.projectFeedbackClient.projectFeedback_Put(this.selectedAssignmentId, false, this.accepted, {
          data: this.selectedFile,
          fileName: this.selectedFile.name
        })

        if (personResponse.errorMessage) {
          this.errorMessage = personResponse.errorMessage
        } else {
          if (this.accepted) {
            this.fileUploadedDialog = true
          }
        }
      }
      this.accepted = false
      this.inputKey++
    },

    async convertProjectDetails (
      projectRetrieved: ProjectGetResponse,
      assignmentsRetrieved: Array<AssignmentGetResponse>,
      projectFeedbacksRetrieved?: Dictionary<string>
    ) {
      this.projectName = projectRetrieved.name
      this.startDateProject = i18n.d(new Date(projectRetrieved.startDate), 'short')
      this.endDateProject = i18n.d(new Date(projectRetrieved.endDate), 'short')
      this.projectPeriod = this.startDateProject + ' - ' + this.endDateProject
      this.plName = projectRetrieved.plName
      this.customerName = projectRetrieved.customerName
      this.customerId = projectRetrieved.customerId
      this.isEditable = projectRetrieved.isEditable

      this.assignments = Array<DisplayAssignment>()
      assignmentsRetrieved.forEach((assignment: AssignmentGetResponse) => {
        this.addAssignments(assignment, projectFeedbacksRetrieved)
      })
    },

    async addAssignments (assignment: AssignmentGetResponse, projectFeedbacksRetrieved?: Dictionary<string>) {
      const processedAssignment: DisplayAssignment = {
        assignmentId: assignment.id,
        personId: assignment.personId,
        personName: assignment.personName,
        roleId: assignment.roleId,
        roleName: assignment.roleName,
        projectId: assignment.projectId,
        projectName: assignment.projectName,
        startDateProject: assignment.startDateProject,
        endDateProject: assignment.endDateProject,
        customerName: assignment.customerName,
        customerId: this.customerId,
        startDate: (assignment.startDate).substr(0, 10),
        endDate: (assignment.endDate).substr(0, 10),
        projectFeedbackStatus: i18n.t('feedback.isNotAvailable') as string,
        isEditable: false
      }

      if (projectFeedbacksRetrieved === undefined) {
        processedAssignment.projectFeedbackStatus = i18n.t('feedback.isUnknown') as string
        this.assignments.push(processedAssignment)
        return
      }

      if (assignment.id in projectFeedbacksRetrieved) {
        processedAssignment.projectFeedbackStatus = i18n.t('feedback.isAvailable',
          { submitDate: i18n.d(new Date(projectFeedbacksRetrieved[assignment.id])) }) as string
      }
      this.assignments.push(processedAssignment)
    },

    async loadProjectDetails () {
      this.projectDetailsLoading = true
      this.projectId = parseInt(this.$route.params.id)
      if (isNaN(this.projectId)) {
        await this.$router.push({ name: 'ListProject' })
        return
      }

      let projectResponse: ProjectGetResponse
      let assignmentsResponse: AssignmentGetAllResponse
      let projectFeedbacksResponse: ProjectFeedbackGetAllResponse

      try {
        projectResponse = await this.projectClient.project_Get(this.projectId)
        assignmentsResponse = await this.assignmentClient.assignment_GetAllForProject(this.projectId)
        projectFeedbacksResponse = await this.projectFeedbackClient.projectFeedback_GetAllForProject(this.projectId)
      } catch {
        await this.$router.push({ name: 'ListProject' })
        return
      }

      if (projectResponse.errorMessage !== '') {
        this.errorMessage = projectResponse.errorMessage
      } else if (assignmentsResponse.errorMessage !== '') {
        this.errorMessage = assignmentsResponse.errorMessage
      } else if (projectFeedbacksResponse.errorMessage !== '') {
        await this.convertProjectDetails(projectResponse, assignmentsResponse.assignments)
        this.canGiveProjectFeedback = false
      } else {
        await this.convertProjectDetails(projectResponse, assignmentsResponse.assignments, projectFeedbacksResponse.projectFeedbacks)
        this.canGiveProjectFeedback = await isProjectFeedbackActionAvailable(this.projectId)
      }

      this.projectDetailsLoading = false
    },

    async validationDeleteAssignment (toDeleteAssignment: DisplayAssignment, isEditable: boolean) {
      if (isEditable) {
        this.showSuccessMessageEdit = false
        this.showSuccessMessageDelete = false
        this.errorMessage = ''
        if (confirm(this.$i18n.t('detailsView.deleteAssignmentMessage',
          {
            personName: toDeleteAssignment.personName,
            projectName: toDeleteAssignment.projectName
          }) as string)
        ) {
          await this.deleteAssignment(toDeleteAssignment)
          await this.loadProjectDetails()
        }
      }
    },

    async deleteAssignment (toDeleteAssignment: DisplayAssignment) {
      const assignmentResponse = await this.assignmentClient.assignment_Delete(toDeleteAssignment.assignmentId, toDeleteAssignment.customerId)
      this.errorMessage = assignmentResponse.errorMessage
    },

    openEditAssignment: function (editedAssignment: DisplayAssignment, isEditable: boolean) {
      if (isEditable) {
        this.showSuccessMessageEdit = false
        this.showSuccessMessageDelete = false
        this.dialogEdit = true
        this.errorMessage = ''
        this.toEditAssignment = editedAssignment
      }
    },

    async closeEditAssignment () {
      this.dialogEdit = false
      await this.loadProjectDetails()
    },

    editAssignmentSuccessful: function () {
      this.showSuccessMessageEdit = true
    },
    async uploadFeedback (item: DisplayAssignment) {
      if (!this.dialogEdit) {
        await this.$router.push({
          name: 'uploaded',
          params: {
            projectId: String(this.projectId),
            assignmentId: String(item.assignmentId)
          }
        })
      }
    },
    async openProjectFeedback (item: DisplayAssignment) {
      if (!this.dialogEdit) {
        await this.$router.push({
          name: 'AddProjectFeedback',
          params: {
            projectId: String(this.projectId),
            assignmentId: String(item.assignmentId)
          }
        })
      }
    }
  },

  async beforeMount () {
    await this.loadProjectDetails()

    this.feedbackCreatedDialog = !!(this.$route.params.feedbackCreated)

    this.createdAssignmentPerson = this.$route.params.createdAssignmentPerson
    this.createdAssignmentProject = this.$route.params.createdAssignmentProject
    this.createdAssignmentRole = this.$route.params.createdAssignmentRole
    if (!(this.createdAssignmentPerson === undefined && this.createdAssignmentProject === undefined && this.createdAssignmentRole === undefined)) {
      this.assignmentCreatedDialog = true
    }
    this.fileUploadedDialog = !!(this.$route.params.fileUploaded)
  }
})
