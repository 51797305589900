













































import Vue from 'vue'
import { Validation } from '@/methods/Validation'
import { ReportClient } from '@/api/clients'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'VerifyKeyCreateReportDialog',

  props: {
    showDialog: Boolean
  },

  data: () => ({
    errorMessage: '',
    privateKey: '',
    validation: new Validation(),
    reportClient: new ReportClient()
  }),

  watch: {
    privateKey: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  methods: {
    async giveKey () {
      if (!this.checkForm()) {
        return false
      }
      const reportResponse = await this.reportClient.report_ValidatePrivateKey(this.privateKey)
      this.errorMessage = reportResponse.errorMessage
      return !this.errorMessage
    },

    checkForm: function () {
      this.errorMessage = ''
      return (this.validation.notempty(this.privateKey) === true)
    },

    close () {
      this.$emit('close')
      this.privateKey = ''
      this.errorMessage = ''
    },

    async save () {
      const refForm = this.$refs.form as HTMLFormElement
      refForm.validate()

      if (await this.giveKey()) {
        await this.createReport()

        if (this.errorMessage === '') {
          this.close()
        }
      }
    },

    async createReport () {
      const report = await this.reportClient.report_GenerateFeedbackReport(this.privateKey)

      // sending both BaseResponse and FileResponse over the same response code doesnt work and
      // re-parsing it here seems too complex for this edge case. Normally giveKey() will fail first.
      if (!report || !report.headers || report.headers['content-type'].startsWith('application/json')) {
        this.errorMessage = i18n.t('generateReportNotSuccessful') as string
        return
      }

      // could also use window.open for better performance (but cant choose filename with this method)
      const link = document.createElement('a')
      link.href = URL.createObjectURL(report?.data)
      link.download = i18n.t('fileNameExcelReport') + '_' + new Date(Date.now()).toISOString().split('T')[0].replaceAll('-', '') as string
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
})
