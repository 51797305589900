
















































































































import Vue from 'vue'
import { AuthClient, AuthResponse, FrontendAction, LoginStatusResponse } from '@/api/clients'
import { isActionAvailable } from '@/api/availableActions'

import auth from './api/auth'

export default Vue.extend({
  name: 'App',

  data: () => ({
    loggedIn: false,
    hasToChangePw: false,
    personName: '',
    userIsPerson: true,
    personId: 0,
    errorSnackbar: false,
    errorMessage: '',
    frontendActions: FrontendAction,
    authClient: new AuthClient(),
    loggingIn: false,
    logoutFailed: false,
    user: {},
    accessToken: ''
  }),

  async created () {
    // Basic setup of MSAL helper with client id, or give up
    await auth.configure(process.env.VUE_APP_CLIENT_ID, process.env.VUE_APP_AUTHORITY)

    // Restore any cached or saved local user
    this.user = auth.user()
  },

  methods: {
    isActionAvailable,

    async validateLoginStatus () {
      const userCookie = localStorage.getItem('user')
      if (userCookie != null) {
        const userCookieObject = JSON.parse(userCookie)
        var cookie = {
          userId: userCookieObject.userId,
          personId: userCookieObject.personId,
          token: userCookieObject.token
        }
        if (!(await this.authClient.auth_ValidateLoginStatus(cookie))) {
          console.log('login not valid')
          localStorage.removeItem('user')
          await this.$router.push({ name: 'Login' })
        } else {
          this.personId = userCookieObject.personId
        }
      }
    },

    async updateLoginStatus () {
      const actionsResponse = await this.authClient.auth_GetAllowedFrontendActions()

      if (actionsResponse.errorMessage) {
        // dont show snackbar, if closed by user.
        if (actionsResponse.errorMessage !== this.errorMessage) {
          this.errorSnackbar = true
        }

        localStorage.removeItem('user')
        localStorage.removeItem('availableActions')

        this.updateLocalDataIfChanged(actionsResponse.errorMessage)
        return
      }

      localStorage.setItem('availableActions', JSON.stringify(actionsResponse.actions))

      const loginStatusResponse = await this.authClient.auth_GetLoginStatus()
      this.updateLocalDataIfChanged(loginStatusResponse.errorMessage, loginStatusResponse)
    },

    /**
     * This function will avoid invoking update() again, as that leads to an infinite loop.
     */
    updateLocalDataIfChanged (errorMessage: string, loginStatus?: LoginStatusResponse) {
      if (this.errorMessage !== errorMessage) {
        this.errorMessage = errorMessage
      }

      if (this.loggedIn !== !errorMessage) {
        this.loggedIn = !errorMessage
      }

      const user: AuthResponse = JSON.parse(localStorage.getItem('user') as string)
      if (!loginStatus) {
        if (user && user.token && this.personName !== user.firstName + ' ' + user.lastName) {
          this.personName = user.firstName + ' ' + user.lastName
        }

        if (user && this.hasToChangePw !== user.autogeneratedPw) {
          this.hasToChangePw = user.autogeneratedPw
        }
        return
      }

      if (this.personName !== loginStatus.firstName + ' ' + loginStatus.lastName) {
        this.personName = loginStatus.firstName + ' ' + loginStatus.lastName
      }

      if (this.hasToChangePw !== loginStatus.autogeneratedPw) {
        this.hasToChangePw = loginStatus.autogeneratedPw
      }

      user.mail = loginStatus.mail
      user.firstName = loginStatus.firstName
      user.lastName = loginStatus.lastName
      user.autogeneratedPw = loginStatus.autogeneratedPw
      localStorage.setItem('user', JSON.stringify(user))
    },

    async logoutUser () {
      this.loggingIn = true
      try {
        this.user = auth.user()
        if (this.user) {
          await auth.logout()
        }
        const userCookie = localStorage.getItem('user')
        if (userCookie != null) {
          const userCookieObject = JSON.parse(userCookie)
          var cookie = {
            userId: userCookieObject.userId,
            personId: userCookieObject.personId,
            token: userCookieObject.token
          }
          await this.authClient.auth_Logout(cookie)
          localStorage.removeItem('user')
          await this.$router.push({ name: 'Login' })
        }
      } catch {
        this.logoutFailed = true
      }
      this.loggingIn = false
    }
  },

  /**
   * Using beforeUpdate, since updated has to be synchronous.
   */
  async beforeUpdate () {
    await this.validateLoginStatus()
    await this.updateLoginStatus()
  },

  async beforeMount () {
    await this.validateLoginStatus()
    await this.updateLoginStatus()
  }
})
