









































































































































import Vue, { PropType } from 'vue'
import { ProjectClient, ProjectGetResponse } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import { DateConverter } from '@/methods/DateConverter'

export default Vue.extend({
  name: 'EditDialogProject',

  props: {
    showDialog: Boolean,
    project: Object as PropType<ProjectGetResponse>,
    listOfPl: Array as PropType<Record<string, unknown>[]>,
    customers: Array as PropType<Record<string, unknown>[]>
  },

  data: () => ({
    errorMessage: '',
    validation: new Validation(),
    dateConverter: new DateConverter(),
    originalCustomerId: 0,
    toEditProject: {
      id: 0,
      projectName: '',
      plId: 0,
      customerId: 0,
      startDate: '',
      startDateFormatted: '',
      endDate: '',
      endDateFormatted: '',
      startDateMenu: false,
      endDateMenu: false
    },
    projectClient: new ProjectClient()
  }),

  watch: {
    showDialog: {
      handler: function (newVal) {
        if (newVal === false) {
          return
        }
        this.originalCustomerId = this.project.customerId
        this.toEditProject = {
          id: this.project.id,
          projectName: this.project.name,
          plId: this.project.plId,
          customerId: this.project.customerId,
          startDate: this.project.startDate.substr(0, 10),
          endDate: this.project.endDate.substr(0, 10),
          startDateFormatted: this.dateConverter.formatDate(this.project.startDate),
          endDateFormatted: this.dateConverter.formatDate(this.project.endDate),
          startDateMenu: false,
          endDateMenu: false
        }
      },
      immediate: true
    },

    'toEditProject.startDate' () {
      this.toEditProject.startDateFormatted = this.dateConverter.formatDate(this.toEditProject.startDate)
      this.errorMessage = ''
    },

    'toEditProject.endDate' () {
      this.toEditProject.endDateFormatted = this.dateConverter.formatDate(this.toEditProject.endDate)
      this.errorMessage = ''
    },

    // somehow, this does not work with a computed property as in most other files
    'toEditProject.projectName' () {
      this.errorMessage = ''
    },

    'toEditProject.plName' () {
      this.errorMessage = ''
    },

    'toEditProject.customerName' () {
      this.errorMessage = ''
    }
  },

  methods: {
    async editProject () {
      if (!this.checkForm()) {
        return
      }
      const projectResponse = await this.projectClient.project_Edit({
        id: this.toEditProject.id,
        name: this.toEditProject.projectName.trim(),
        customerId: this.toEditProject.customerId,
        plId: this.toEditProject.plId,
        startDate: this.toEditProject.startDate,
        endDate: this.toEditProject.endDate
      }, this.originalCustomerId)
      this.errorMessage = projectResponse.errorMessage
      if (projectResponse.errorMessage) {
        return
      }
      this.$emit('success')
    },

    checkForm: function () {
      if (!this.validation.validDates(this.toEditProject.startDate, this.toEditProject.endDate)) {
        this.errorMessage = this.$i18n.t('error.startBeforeEndDate') as string
        return false
      }
      this.errorMessage = ''
      return (this.validation.notempty(this.toEditProject.projectName) === true &&
        this.validation.notNumberNull(this.toEditProject.plId) === true &&
        this.validation.notNumberNull(this.toEditProject.customerId) === true)
    },

    close () {
      this.$emit('close')
      this.errorMessage = ''
    },

    async save () {
      await this.editProject()
      if (this.errorMessage === '' && this.checkForm()) {
        this.close()
      }
    }
  }
})
