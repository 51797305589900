



































































































































import Vue from 'vue'
import { ProjectClient, CustomerClient, PersonClient, CustomerGetResponse } from '@/api/clients'
import { Validation } from '@/methods/Validation'
import { DateConverter } from '@/methods/DateConverter'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'AddProject',
  data: () => ({
    projectName: '',
    customerId: 0,
    plId: 0,
    errorMessage: '',
    listOfCustomer: Array<Record<string, unknown>>(),
    customerLoading: true,
    listOfPersons: Array<Record<string, unknown>>(),
    personsLoading: true,
    validation: new Validation(),
    dateConverter: new DateConverter(),
    startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    startDateFormatted: '',
    endDate: '',
    endDateFormatted: '',
    personClient: new PersonClient(),
    customerClient: new CustomerClient(),
    projectClient: new ProjectClient(),
    startDateMenu: false,
    endDateMenu: false
  }),

  watch: {
    startDate () {
      this.startDateFormatted = this.dateConverter.formatDate(this.startDate)
    },
    endDate () {
      this.endDateFormatted = this.dateConverter.formatDate(this.endDate)
    },
    formData: {
      handler: function () {
        this.errorMessage = ''
      },
      deep: true
    }
  },

  computed: {
    formData () {
      return [this.projectName, this.plId, this.customerId, this.startDate, this.endDate]
    }
  },

  methods: {
    async loadListOfPersons () {
      this.personsLoading = true

      const personNamesResponse = await this.personClient.person_GetAllNames()
      if (personNamesResponse.errorMessage === '') {
        const names = personNamesResponse.names
        this.listOfPersons = names.map(person => { return { value: person.id, text: person.name } })
      } else {
        const projectLocalizedText = i18n.t('project')
        const personLocalizedText = i18n.t('employee')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: projectLocalizedText, listOf: personLocalizedText }) as string
      }

      this.personsLoading = false
    },

    listCustomer: function (customerRetrieved: Array<CustomerGetResponse>) {
      customerRetrieved.forEach((customer: CustomerGetResponse) => {
        this.listOfCustomer.push({
          text: customer.name,
          value: '' + customer.id
        })
      })
    },

    async loadListOfCustomer () {
      this.customerLoading = true

      const customerResponse = await this.customerClient.customer_GetCustomerList()
      if (customerResponse.errorMessage === '') {
        this.listCustomer(customerResponse.customers)
      } else {
        const projectLocalizedText = i18n.t('project')
        const customerLocalizedText = i18n.t('customer')
        this.errorMessage = i18n.t('addUnavailableListNotAccessible',
          { objToAdd: projectLocalizedText, listOf: customerLocalizedText }) as string
      }

      this.customerLoading = false
    },

    async createProject () {
      const refForm = this.$refs.projectForm as HTMLFormElement
      refForm.validate()

      // This is the first of the two input checks. The second is done by the database.
      if (!this.checkForm()) {
        return
      }
      const projectResponse = await this.projectClient.project_Post({
        name: this.projectName.trim(),
        plId: this.plId,
        startDate: this.startDate,
        endDate: this.endDate
      }, this.customerId)
      // The error message will contain an error, if a validation step failed for the database which was not checked here.
      this.errorMessage = projectResponse.errorMessage
      if (projectResponse.errorMessage) {
        return
      }

      // go back to project list
      // params will be used for user feedback
      await this.$router.push({
        name: 'ListProjectWithParamsCreatedProject',
        params: {
          createdCustomerName: String((this.listOfCustomer.find(c => c.value === this.customerId) as Record<string, unknown>).text),
          createdProjectName: this.projectName,
          createdPlName: this.listOfPersons.find(pl => pl.value === this.plId)!.text as string
        }
      })
    },

    /**
     * Calls all validation functions.
     * @returns True, if no errors were found.
     */
    checkForm: function () {
      if (!this.validation.validDates(this.startDate, this.endDate)) {
        this.errorMessage = this.$i18n.t('error.startBeforeEndDate') as string
        return false
      }
      this.errorMessage = ''
      return (this.validation.notempty(this.projectName) === true &&
        this.validation.notNumberNull(this.customerId) === true &&
        this.validation.notNumberNull(this.plId) === true
      )
    }

  },

  async beforeMount () {
    await this.loadListOfPersons()
    await this.loadListOfCustomer()
    this.startDateFormatted = this.dateConverter.formatDate(this.startDate)
  }
})
