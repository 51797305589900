import i18n from '@/i18n'
import { JobLevel } from '@/api/clients'

export class EnumConverter {
  /**
   * Converts a string or value of the enum to a localized text for department locations.
   * Returns the locale key, if not match is found e.g. 'departmentLocation.Stuttgart'.
   */
  toLocalizedTextForJobLevels (enumInput: string | JobLevel): string {
    if (typeof enumInput === 'string') {
      return i18n.t('jobLevels.' + enumInput) as string
    }

    return i18n.t('jobLevels.' + JobLevel[enumInput]) as string
  }

  /**
   * Converts a localized string of the enum to the corresponding value for department locations.
   * @throws {RangeError} If, no match is found.
   */
  toEnumValueForJobLevels (localizedStr: string): JobLevel {
    for (const level in JobLevel) {
      const isNumber = isFinite(parseInt(level))
      if (!isNumber) {
        if (localizedStr === i18n.t('jobLevels.' + level) as string) {
          return JobLevel[level as keyof typeof JobLevel]
        }
      }
    }

    throw new RangeError(localizedStr + ' not found in JobLevel')
  }
}
